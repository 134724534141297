import React from "react";

import NewListingDefault from "./input_fields/NewListingDefault";
import SelectedFeatures from "./selected_features/SelectedFeatures";
import PredictedFeatures from "./generated_features/PredictedFeatures";


const NewListing = ({scrollToBottom}) => {

    return (
        <div className="w-full h-full m-0 flex items-center content-center flex-row flex-nowrap justify-center gap-0">
            <NewListingDefault scrollToBottom={scrollToBottom}/>
            <SelectedFeatures/>
            <PredictedFeatures/>
        </div>
    )
};

export default NewListing;