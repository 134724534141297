import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import SelectedFeature from "./SelectedFeature";
import AddFeature from "./AddFeature";


const SelectedImageFeatures = () => {

    const selectedImageFeaturesStore = useSelector(state => state.propertyFeatures.selectedImageFeatures);

    const [selectedImageFeatures, setSelectedImageFeatures] = useState([]);

    useEffect(() => {
        setSelectedImageFeatures(selectedImageFeaturesStore);
    }, [selectedImageFeaturesStore]);

    return (
        <div className="h-1/2 flex flex-col items-center justify-start gap-6">
            <p className="text-center mt-4 mb-0 text-lg font-medium">Selected Image features</p>
            <div className="w-[70%]">
                <AddFeature featureType={"image"}/>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 overflow-scroll">
                {
                    selectedImageFeatures.map((feature, featureId) => (
                        <SelectedFeature
                            key={feature.key}
                            featureId={featureId}
                            featureType={"image"} 
                            featureName={feature.name}
                            featureKey={feature.key}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default SelectedImageFeatures;
