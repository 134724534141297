import { TRIAL_PERIOD } from "./constants";
import { addDaysToStringDateAndConvert, convertStringDate } from "./listings";


export function parseUserData(userData) {
    try {
        // calculate expiration date for the current plan,
        // and convert date format, if needed
        let planExpirationDate = "";
        if (userData.subscriptionPlan === "free") {
            const trialPeriodDays = parseInt(TRIAL_PERIOD, 10)
            planExpirationDate = addDaysToStringDateAndConvert(userData.registeredAt, trialPeriodDays);
        } else {
            // TODO: it's hardcoded for now, until I get Stripe
            planExpirationDate = "09-18-2024";
        }

        // convert the reigstration date into a string to display
        const registeredAt = convertStringDate(userData.registeredAt)

        // create internal data object
        const userDataInternal = {
            "email": userData.email,
            "registeredAt": registeredAt,
            "subscriptionPlan": userData.subscriptionPlan,
            "planEndsAt": planExpirationDate,
        };

        return {
            "successful": true,
            "data": userDataInternal,
        };        
    } catch (error) {
        return {
            "successful": false,
            "data": error,
        };   
    };
}

export function determineNewUser(registeredAtString) {
    // convert the registered date into a proper date
    const registeredAtTimestamp = Date.parse(registeredAtString);
    const registeredAtDate = new Date(registeredAtTimestamp);

    // determine if the user is already registered
    // criteria: if registration date is less then 10 seconds from now
    const now = new Date();
    const secondsSinceRegistered = (now.getTime() - registeredAtDate.getTime())/1000;
    
    if (secondsSinceRegistered < 10) {
        return true;
    } else {
        return false;
    }
    
}