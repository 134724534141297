

export function emailIsValid(email) {
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (email.match(validEmailRegex)) {
        return true
    } else {
        return false
    };
};

export function paswordIsValid(password) {
    const validPasswordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/g;
    if (password.match(validPasswordRegex)) {
        return true
    } else {
        return false
    };
};

export function validateLoginFormError(email, password) {
    // check that all fields are non-empty
    if (!email || !password) {
        return "All fields must be filled"
    };

    // check for email format
    if (!emailIsValid(email)) {
        return "Email format is incorrect"
    }

    return null;
};

export function validateRegisterFormError(email, password) {
    // check that all fields are non-empty
    if (!email || !password) {
        return "All fields must be filled"
    };

    // check for email format
    if (!emailIsValid(email)) {
        return "Email format is incorrect"
    };

    // check for password format
    if (!paswordIsValid(password)) {
        return "Your password must be at least 8 characters long, and contain at least one number and one letter"
    };

    return null;
};