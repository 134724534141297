import React, { useEffect, useState } from "react";

import howItWorksFirst from 'assets/images/howitworks_1.png';
import howItWorksSecond from 'assets/images/howitworks_2.png';
import howItWorksThrid from 'assets/images/howitworks_3.png';
import leftScroll from "assets/icons/scroll_left.svg";
import rightScroll from "assets/icons/scroll_right.svg";
import companyLogo from "assets/icons/propstory_logo_blue.svg";

const TutorialSlider = () => {

    const [selected, setSelected] = useState(0);
    const four = 4;

    function handleLeftClick() {
        if (selected > 0) {
            const newSelected = selected - 1
            setSelected(newSelected)
        } else {
            setSelected(3);
        }
    };

    function handleRightClick() {
        if (selected < 3) {
            const newSelected = selected + 1
            setSelected(newSelected)
        } else {
            setSelected(0);
        }
    };

    return (
        <div className="w-full flex flex-row justify-center items-center align-center gap-4">
            {/* scroll left */}
            <button 
                className="ursor-pointer border-none transition-all duration-300 bg-inherit hover:opacity-50"  
                onClick={handleLeftClick}>
                <img className="w-[1.5rem] h-[2.5rem" src={leftScroll}/>
            </button>


            <div className="w-[70%] h-[30rem] flex flex-col gap-8 justify-between items-center">
                {/* zero step */}
                <p className="w-full text-center text-lg font-medium" style={{display: selected !==0 ? "none": ""}}>Welcome to PropStory!</p>
                <div className="w-full h-[9rem] flex justify-center" style={{display: selected !==0 ? "none": ""}}>
                    <img src={companyLogo} className="w-[20rem] h-[8rem]" style={{display: selected !==0 ? "none": ""}}/>
                </div>
                <p className="w-full text-center" style={{display: selected !==0 ? "none": ""}}>You can now go and create your first listing. Scroll for further instructions. We are happy to see you :)</p>
                {/* <img src={howItWorksFirst} className="w-[15rem] h-[18rem]" style={{display: selected !==1 ? "none": ""}}/> */}

                {/* first step */}
                <p className="w-full text-center text-lg font-medium h-[2rem]" style={{display: selected !==1 ? "none": ""}}>Step 1: Property information</p>
                <div className="w-full h-[20rem] flex justify-center" style={{display: selected !==1 ? "none": ""}}>
                    <img src={howItWorksFirst} className="w-[14.5rem] h-[18rem]" style={{display: selected !==1 ? "none": ""}}/>
                </div>
                <p className="w-full text-center h-[4.5rem]" style={{display: selected !==1 ? "none": ""}}>Fill in the basic info, like address, property images, and listing details. Then, click “Generate Features”</p>
                
                {/* second step */}
                <p className="w-full text-center text-lg font-medium h-[2rem]" style={{display: selected !==2 ? "none": ""}}>Step 2: Select property features</p>
                <div className="w-full h-[20rem] flex justify-center" style={{display: selected !==2 ? "none": ""}}>
                    <img src={howItWorksSecond} className="w-[22rem] h-[18rem]" style={{display: selected !==2 ? "none": ""}}/>
                </div>
                <p className="w-full text-center h-[4.5rem]" style={{display: selected !==2 ? "none": ""}}>Pick/edit the suggested features, or add you own ones, and then click “Generate Description” button</p>

                {/* third step */}
                <p className="w-full text-center text-lg font-medium h-[2rem]" style={{display: selected !==3 ? "none": ""}}>Step 3: Get the description</p>
                <div className="w-full h-[20rem] flex justify-center" style={{display: selected !==3 ? "none": ""}}>
                    <img src={howItWorksThrid} className="w-[26.5rem] h-[18rem]" style={{display: selected !==3 ? "none": ""}}/>
                </div>
                <p className="w-full text-center h-[4.5rem]" style={{display: selected !==3 ? "none": ""}}>Edit and copy the description you got, then you could upload it to any MLS, or send to the client</p>
            
                {/* dots for slides */}
                <div className="flex flex-row gap-4 align-center">
                    <button className="text-black cursor:pointer color-black w-[12px] h-[12px] duration-300 rounded-full hover:opacity-50" style={{backgroundColor: selected === 0 ? "rgb(41, 162, 254)" : "rgb(179, 222, 254)"}} onClick={() => setSelected(0)}></button>
                    <button className="text-black cursor:pointer color-black w-[12px] h-[12px] duration-300 rounded-full hover:opacity-50" style={{backgroundColor: selected === 1 ? "rgb(41, 162, 254)" : "rgb(179, 222, 254)"}} onClick={() => setSelected(1)}></button>
                    <button className="text-black cursor:pointer color-black w-[12px] h-[12px] duration-300 rounded-full hover:opacity-50" style={{backgroundColor: selected === 2 ? "rgb(41, 162, 254)" : "rgb(179, 222, 254)"}} onClick={() => setSelected(2)}></button>
                    <button className="text-black cursor:pointer color-black w-[12px] h-[12px] duration-300 rounded-full hover:opacity-50" style={{backgroundColor: selected === 3 ? "rgb(41, 162, 254)" : "rgb(179, 222, 254)"}} onClick={() => setSelected(3)}></button>
                </div>
            </div>

            {/* scroll right */}
            <button 
                className="cursor-pointer border-none transition-all duration-300 bg-inherit hover:opacity-50" 
                onClick={handleRightClick}>
                    <img className="w-[1.5rem] h-[2.5rem]" src={rightScroll}/>
            </button>
        </div>

    );
  }
  
  export default TutorialSlider;