import React from "react";

import PrivateHeader from "components/header/PrivateHeader";
import Contact from "components/landing/Contact";

const ContactPage = () => {

    return (
        <div className="fixed w-full h-full left-0 top-0 overflow-scroll" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PrivateHeader/>
            <Contact/>
        </div>
    )
};

export default ContactPage;