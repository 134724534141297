import React, {useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PrivateHeader from "components/header/PrivateHeader";
import NewListing from "components/listings/new_listing/NewListing";
import GeneratedDescription from "components/listings/new_listing/generated_description/GeneratedDescription";
import Tutorial from "components/popups/Tutorial";

const NewListingPage = () => {

    const location = useLocation();

    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        document.title = "New Listing - PropStory.ai"

        // show instructions, if needed
        const showTutorial = new URLSearchParams(location.search).get("tutorial")
        if (showTutorial === "true") {
            setShowTutorial(true);
        }
    }, []);

    const propertyInfoSavedStore = useSelector(state => state.listingState.listingSaved);
    const descriptionGeneratedStore = useSelector(state => state.listingState.descriptionGenerated); 

    const [propertyInfoSaved, setPropertyInfoSaved] = useState(false);
    const [descriptionGenerated, setDescriptionGenerated] = useState(false);

    const pageEndRef = useRef(null);

    // scroll when generated
    // when component is still null, wait a bit for it ti rerender
    function scrollToBottom() {
        if (pageEndRef.current === null) {
            setTimeout(() => pageEndRef.current.scrollIntoView({behavior: 'smooth'}), 500)
            return;
        };
        pageEndRef.current.scrollIntoView({behavior: 'smooth'});
    };

    // if the info was saved
    useEffect(() => {
        setPropertyInfoSaved(propertyInfoSavedStore);
    }, [propertyInfoSavedStore]);

    // detect generated description
    useEffect(() => {
        setDescriptionGenerated(descriptionGeneratedStore);
    }, [descriptionGeneratedStore])

    // prevent leaving without saving
    useEffect(() => {
        const alertUser = e => {
            e.preventDefault();
            e.returnValue = '';
        };

        if (propertyInfoSaved === false) {
            window.addEventListener('beforeunload', alertUser);
        };
        return () => {
          window.removeEventListener('beforeunload', alertUser);
        }
    }, []);


    return (
        <div className="w-full h-full m-0 flex-nowrap overflow-scroll">
            <PrivateHeader/>
            {showTutorial ? <Tutorial setOpen={setShowTutorial}/>: null}
            <div className="w-full h-[93vh] m-0">
                <NewListing scrollToBottom={scrollToBottom}/>
            </div>
            {
                descriptionGenerated ?
                <div className="w-full h-[100vh] m-0" ref={pageEndRef}>
                    <GeneratedDescription/>
                </div>: null
            }
        </div>
    )
};

export default NewListingPage;