import { apiPrivate } from "./base";

export async function getCurrentUser() {
    try {
        const response = await apiPrivate.get(`/users/info/me`);

        return {
            "successful": true,
            "data": response.data,
        };
    } catch (error) {
        let errorDetails = "";
        if (error.response.status == 404) {
            errorDetails = error.response["data"]["detail"]
        } else {
            errorDetails = "Some unkown error occured. Please, try again"
        };
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
};