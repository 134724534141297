import axios from 'axios';

import store from 'state/store';
import { setTokens } from 'state/slices/auth';
import { refreshAccessToken } from 'auth/auth';
import { logUserOut } from 'state/slices/auth';
import { API_URL } from 'utils/constants';


// API client for unportected endpoints
export const apiPublic = axios.create({
  baseURL: API_URL,
});

// API client for protected endpoints
export const apiPrivate = axios.create({
    baseURL: API_URL,
  });

apiPrivate.interceptors.request.use(
    (config) => {
      const accessToken = store.getState().auth.accessToken;

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
);

apiPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
  
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
  
          const refreshToken = store.getState().auth.refreshToken;

          const response = await refreshAccessToken(refreshToken);
        
          // if refreshing token fails, redirect to login
          if (response.successful === false) {
            store.dispatch(logUserOut());
            window.location.href = "/login";
          };

          const data = response.data;
          const tokens = {
              "accessToken": data.accessToken,
              "refreshToken": data.refreshToken,
          };
          store.dispatch(setTokens(tokens));

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
          return axios(originalRequest);
        }
  
      return Promise.reject(error);
    }
);
