import React from "react";

import imagePredictionExample from 'assets/images/image_prediciton_example_property_stock.png';
import exampleImageFeatures from 'assets/images/example_image_features.png';
import exampleLocationFeatures from 'assets/images/examlpe_location_features.png';
import exampleDescriptionStructure from 'assets/images/description_structure_example.png';


const KeyFeaturesSection = () => {
    return (
        <div className="w-full flex flex-col gap-14 justify-between items-center align-center">
            {/* Title */}
            <div className="w-[48rem] text-center mt-12">
                <p className="text-2xl w-full font-bold">Spend your time on money-making activities, not on copywriting</p>
                <p className="text-lg w-full mt-2">We help to point out the best things about the property, and then put them into a compelling description, just within minutes</p>
            </div>

            {/* Features from images */}
            <div className="w-[70rem] flex flex-row">
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[30rem] font-bold">Notice what other agents do not see</p>
                    <p className="text-lg w-[30rem] mt-4">Our smart algorithm helps to discover the most important property features from photos</p>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center align-center gap-4">
                    <img src={imagePredictionExample} className="w-[16rem] h-[10rem] rounded-2xl border border-black/30"/>
                    <p className="text-5xl" style={{color: "rgb(56, 132, 247)"}}>{'\u2192'}</p>
                    <img src={exampleImageFeatures} className="h-[14rem]"/>
                </div>
            </div>

            {/* Features from address */}
            <div className="w-[70rem] flex flex-row gap-4">
                <div className="w-1/2 flex flex-row justify-start items-center align-center">
                    <img src={exampleLocationFeatures} className="w-[24rem] h-[20rem]"/>
                </div>
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[30rem] font-bold">Get to know the local neighborhood</p>
                    <p className="text-lg w-[30rem] mt-4">We scan the area around the property and use AI to highlight unique features of the location</p>
                </div>
            </div>

            {/* Final description is good */}
            <div className="w-[70rem] flex flex-row mb-12">
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[27rem] font-bold">Generate a description optimised for conversion and readability</p>
                    <p className="text-lg w-[27rem] mt-4">The final text follows a logical structure with a call-to-action at the end</p>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center align-center gap-4">
                    <img src={exampleDescriptionStructure} className="w-[34rem] h-[22rem]"/>
                </div>
            </div>
        </div>
    );
};

export default KeyFeaturesSection;