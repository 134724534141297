import React from "react";
import { Oval } from 'react-loader-spinner';

import closeIcon from "assets/icons/close_icon.svg";
import store from "state/store";
import { setDescriptionGenerationAborted } from "state/slices/listingState";

const GeneratingDescriptionPopup = ({setOpen}) => {

    function handleClose() {
        setOpen(false);
        store.dispatch(setDescriptionGenerationAborted(true));
    };

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[75%] w-[34rem] h-[18rem] text-center margin-auto flex flex-col items-center justify-between rounded-2xl border-black bg-white z-50">
                <button 
                    className="w-[1.5rem] h-[1.5rem] mr-4 mt-4 mb-2 self-end cursor-pointer border-none duration-300 bg-inherit hover:opacity-50" 
                    onClick={handleClose}>
                        <img src={closeIcon} className="w-[1.5rem] h-[1.5rem]" />
                </button>
                <p className="text-xl text-black font-semibold mt-0 mb-0">Generating property description</p>
                <p className="text-lg text-left text-black whitespace-pre-wrap ml-8 mr-8 mt-0 mb-4">This could take up to 20-30 seconds</p>
                <Oval
                    visible={true}
                    height="40"
                    width="40"
                    color="#699BF7"
                    secondaryColor="#699BF7"
                    strokeWidth="3.5"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass="mb-8"
                />
            </div>
        </div>
    )
};

export default GeneratingDescriptionPopup;