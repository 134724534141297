import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


import { validateListingTextFieldsError } from "utils/listings";
import { generatePhotoFeatures, generateLocationFeatures, generateDescription } from "api/predictions";

import { v4 as uuidv4 } from 'uuid';
import store from "state/store";
import { updatePredictedImageFeatures, updatePredictedLocationFeatures, updatePropertyDescription } from "state/slices/propertyFeatures";
import { updateAddressError, updateDesiredLengthError } from "state/slices/errors";
import { updateFeaturesGenerated, updateDescritpionGenerated, setFeaturesGenerationAborted, setDescriptionGenerationAborted } from "state/slices/listingState";

import AddressNotFoundPopup from "components/popups/AddressNotFoundPopup";
import GeneratingAllFeaturesPopup from "components/popups/GeneratingAllFeaturesPopup";
import LocationFailurePopup from "components/popups/LocationFailurePopup";
import ImageFailurePopup from "components/popups/ImageFailurePopup";
import ImageLocationFailurePopup from "components/popups/ImageLocationFailurePopup";
import ImagesNotSelectedPopup from "components/popups/ImagesNotSelectedPopup";
import GeneratingDescriptionPopup from "components/popups/GeneratingDescriptionPopup";
import DescriptionFailurePopup from "components/popups/DescriptionFailurePopup";
import PremiumRequiredPopup from "components/popups/PremiumRequiredPopup";
import DemoListingPopup from "components/popups/DemoListingPopup";
import ImageFormattingFailurePopup from "components/popups/ImageFormattingFailurePopup";
import { IMAGE_FORMAT_ERRORS } from "utils/constants";

const GenerateButtons = ({scrollToBottom}) => {

    const addressStore = useSelector(state => state.propertyInfo.address);
    const desiredLengthStore = useSelector(state => state.propertyInfo.desiredLength);
    const propertyTypeStore = useSelector(state => state.propertyInfo.propertyType);
    const listingTypeStore = useSelector(state => state.propertyInfo.listingType);
    const imagesStore = useSelector(state => state.propertyInfo.images);
    const selectedImageFeaturesStore = useSelector(state => state.propertyFeatures.selectedImageFeatures);
    const selectedLocationFeaturesStore = useSelector(state => state.propertyFeatures.selectedLocationFeatures);

    // property info for feature/description generation
    const [images, setImages] = useState([]);
    const [address, setAddress] = useState(null);
    const [desiredLength, setDesiredLength] = useState(null);
    const [propertyType, setPropertyType] = useState("apartment");
    const [listingType, setListingType] = useState(false);
    const [selectedImageFeatures, setSelectedImageFeatures] = useState([]);
    const [selectedLocationFeatures,  setSelectedLocationFeatures] = useState([]);

    // state booleans
    const [featuresSelected, setFeaturesSelected] = useState(false);

    // popups
    const [addressNotFound, setAddressNotFound] = useState(false);
    const [generatingFeatures, setGeneratingFeatures] = useState(false);
    const [locationGenerationFailed, setLocationGenerationFailed] = useState(false);
    const [imageGenerationFailed, setImageGenerationFailed] = useState(false);
    const [allGenerationFailed, setAllGenerationFailed] = useState(false);
    const [imagesNotSelected, setImagesNotSelected] = useState(false);
    const [generatingDescription, setGeneratingDescription] = useState(false);
    const [descriptionGenerationFailed, setDescriptionGenerationFailed] = useState(false);
    const [premiumRequired, setPremiumRequired] = useState(false);
    const [demoListingRestricted, setDemoListingRestricted] = useState(false);
    const [imageFormattingFailed, setImageFormattingFailed] = useState(false);

    // determine if the "Generate Descriptions" button should light up
    // and update the state, also
    useEffect(() => {
        setSelectedImageFeatures(selectedImageFeaturesStore);
        setSelectedLocationFeatures(selectedLocationFeaturesStore);
        if (selectedImageFeaturesStore.length > 0 & selectedLocationFeaturesStore.length > 0) {
            if (featuresSelected === false) {
                setFeaturesSelected(true);
            }
        } else {
            if (featuresSelected === true) {
                setFeaturesSelected(false);
            }
        }
    }, [selectedImageFeaturesStore, selectedLocationFeaturesStore]);


    useEffect(() => {
        setAddress(addressStore);
    }, [addressStore])

    useEffect(() => {
        setDesiredLength(desiredLengthStore);
    }, [desiredLengthStore])

    useEffect(() => {
        setPropertyType(propertyTypeStore);
    }, [propertyTypeStore])

    useEffect(() => {
        setListingType(listingTypeStore);
    }, [listingTypeStore])

    useEffect(() => {
        setImages(imagesStore);
    }, [imagesStore])

    const handleGenerateFeatures = async () => {
        // prohibit actions if this is a demo listing
        const demoListing = store.getState().listingState.demoListingOpen;
        if (demoListing === true) {
            setDemoListingRestricted(true);
            return;
        };

        // check for errors in text fields
        const fieldErrors = validateListingTextFieldsError(address, desiredLength)
        if (fieldErrors !== null) {
            if (fieldErrors.address !== null) {
                store.dispatch(updateAddressError(fieldErrors.address));
            };
            if (fieldErrors.desiredLength !== null) {
                store.dispatch(updateDesiredLengthError(fieldErrors.desiredLength));
            };
            return
        };

        // check that at least one image has been chosen
        if (images.length === 0) {
            setImagesNotSelected(true);
            return;
        }

        // grab the blob part from images, ignorint names
        const imagesBlob = []
        for(let i = 0; i < images.length; i++){
            imagesBlob.push(images[i].imageBlob);
        }

        // make a request with a loading screen
        setGeneratingFeatures(true);
        const [photoResponse, locationResponse] = await Promise.all([
            generatePhotoFeatures(imagesBlob),
            generateLocationFeatures(address),
        ]);

        // if the loading was closed by the user, ignore the results
        const aborted = store.getState().listingState.featuresGenerationAborted;
        if (aborted === true) {
            store.dispatch(setFeaturesGenerationAborted(false));
            return;
        };
        
        // if the request is successfully loaded, close the loading
        setGeneratingFeatures(false);

        // error handling

        // image formatting errors
        if (photoResponse.successful === false) {
            if (IMAGE_FORMAT_ERRORS.includes(photoResponse.data)) {
                setImageFormattingFailed(true);
                return;
            }
        };

        // address not found
        if (locationResponse.successful === false) {
            if (locationResponse.data == "address-not-found") {
                setAddressNotFound(true);
                return;
            } else if (locationResponse.data == "requires-premium") {
                setPremiumRequired(true);
                return;
            }
        };

        // random fails we cannot identify
        if (locationResponse.successful === false && photoResponse.successful === false) {
            setAllGenerationFailed(true);
            return;
        } else if (locationResponse.successful === false) {
            setLocationGenerationFailed(true);
            return;
        } else if (photoResponse.successful === false) {
            setImageGenerationFailed(true);
            return;
        }

        // attach "keys" to predicted features
        const predictedLocationFeatures = locationResponse.data.features.map(
            (feature) => {
                feature["key"] = uuidv4();
                feature.locations.map(
                    (location) => {
                        location["key"] = uuidv4();
                        return location;
                    }
                )
                return feature
            }
        )

        const predictedImageFeatures = photoResponse.data.features.map(
            (feature) => {
                return {
                    "name": feature,
                    "key": uuidv4(),
                }
            }
        )

        store.dispatch(updatePredictedImageFeatures(predictedImageFeatures));
        store.dispatch(updatePredictedLocationFeatures(predictedLocationFeatures));
        store.dispatch(updateFeaturesGenerated(true));
    };

    async function handleGenerateDescription() {
        // prohibit actions if this is a demo listing
        const demoListing = store.getState().listingState.demoListingOpen;
        if (demoListing === true) {
            setDemoListingRestricted(true);
            return;
        }

        if (featuresSelected === false) {
            return;
        }

        // check for errors in text fields
        const fieldErrors = validateListingTextFieldsError(address, desiredLength)
        if (fieldErrors !== null) {
            if (fieldErrors.address !== null) {
                store.dispatch(updateAddressError(fieldErrors.address));
            };
            if (fieldErrors.desiredLength !== null) {
                store.dispatch(updateDesiredLengthError(fieldErrors.desiredLength));
            };
            return
        };

        // remove "key" from all elements
        const selectedImageFeaturesStripped = selectedImageFeatures.map(
            (feature) => feature.name
        )

        setGeneratingDescription(true);
        const response = await generateDescription(
            address,
            selectedImageFeaturesStripped,
            selectedLocationFeatures,
            propertyType,
            listingType,
            desiredLength,
        );

        const aborted = store.getState().listingState.descriptionGenerationAborted;
        if (aborted === true) {
            store.dispatch(setDescriptionGenerationAborted(false));
            return;
        };

        setGeneratingDescription(false);
        if (response.successful === false) {
            console.log(response.data);
            setDescriptionGenerationFailed(true);
            return;
        };

        const propertyDescription = response.data.propertyDescription
        store.dispatch(updatePropertyDescription(propertyDescription));
        store.dispatch(updateDescritpionGenerated(true));
        scrollToBottom();
    };

    return (
        <div className="w-[35rem] mt-8 mb-12 flex flex-row items-center justify-center gap-6">
            {premiumRequired ? <PremiumRequiredPopup setOpen={setPremiumRequired}/>: null}
            {addressNotFound ? <AddressNotFoundPopup setOpen={setAddressNotFound}/>: null}
            {generatingFeatures ? <GeneratingAllFeaturesPopup setOpen={setGeneratingFeatures}/>: null}
            {generatingDescription ? <GeneratingDescriptionPopup setOpen={setGeneratingDescription}/>: null}
            {descriptionGenerationFailed ? <DescriptionFailurePopup setOpen={setDescriptionGenerationFailed}/>: null}
            {locationGenerationFailed ? <LocationFailurePopup setOpen={setLocationGenerationFailed}/>: null}
            {imageGenerationFailed ? <ImageFailurePopup setOpen={setImageGenerationFailed}/>: null}
            {allGenerationFailed ? <ImageLocationFailurePopup setOpen={setAllGenerationFailed}/>: null}
            {imagesNotSelected ? <ImagesNotSelectedPopup setOpen={setImagesNotSelected}/>: null}
            {demoListingRestricted ? <DemoListingPopup setOpen={setDemoListingRestricted}/>: null}
            {imageFormattingFailed ? <ImageFormattingFailurePopup setOpen={setImageFormattingFailed}/>: null}
            <button 
                className="text-normal text-white p-[0.25rem] w-[13rem] border border-solid border-black/40 rounded-lg color-white cursor-pointer transition duration-300 ease-in-out hover:opacity-70"
                style={{"backgroundColor": featuresSelected ? "rgb(237, 69, 69)": "rgb(176, 176, 176)"}}
                onClick={async () => {await handleGenerateDescription();}}>
                    Generate Description
            </button>
            <button 
                className="text-normal text-white p-[0.25rem] w-[13rem] border border-solid border-black/40 rounded-lg color-white cursor-pointer transition duration-300 ease-in-out hover:opacity-70" 
                style={{backgroundColor: "rgb(37, 175, 96)"}}
                onClick={async () => {await handleGenerateFeatures();}}>
                    Generate Features
            </button>
        </div>
    )
};

export default GenerateButtons;