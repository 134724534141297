import React from "react";
import ReactPlayer from 'react-player';

import howItWorksFirst from 'assets/videos/1.mp4';
import howItWorksSecond from 'assets/videos/2.mp4';
import howItWorksThird from 'assets/videos/3.mp4';


const HowItWorksSection = () => {
    return (
        <div className="w-full flex flex-col items-center align-center gap-28" style={{backgroundColor: "rgb(252, 248, 244)"}}>
            {/* Header */}
            <div className="w-full text-center">
                <p className="text-2xl font-bold mt-12">How it works</p>
            </div>

            {/* First step */}
            <div className="w-[58rem] flex flex-row gap-12">
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[25rem] font-bold">Fill in information about the listing</p>
                    <p className="text-lg w-[25rem] mt-4">Like photos, address, and some other basic information about the property</p>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center align-center color">
                    <ReactPlayer
                        url={[howItWorksFirst]}
                        controls={false}
                        playing={true}
                        muted={true}
                        loop={true}
                        width="30rem"
                        height="15rem"
                        // className="react-player"
                    />
                </div>
            </div>

            {/* Second step */}
            <div className="w-[58rem] flex flex-row gap-12">
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[25rem] font-bold">Use AI to generate property features, and then select the ones you like</p>
                    <p className="text-lg w-[25rem] mt-4">You could also edit the suggested features, or add your own</p>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center align-center">
                    <ReactPlayer
                        url={[howItWorksSecond]}
                        controls={false}
                        playing={true}
                        muted={true}
                        loop={true}
                        width="30rem"
                        height="15rem"
                    />
                </div>
            </div>

            {/* Third step */}
            <div className="w-[58rem] flex flex-row gap-12 mb-12">
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <p className="text-xl w-[25rem] font-bold">Generate the description from the chosen features</p>
                    <p className="text-lg w-[25rem] mt-4">You could copy it, edit it, or regenerate the description with the new features</p>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center align-center">
                    <ReactPlayer
                        url={[howItWorksThird]}
                        controls={false}
                        playing={true}
                        muted={true}
                        loop={true}
                        width="30rem"
                        height="15rem"
                    />
                </div>
            </div>
        </div>
    );
};

export default HowItWorksSection;