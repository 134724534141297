import React from "react";

import PrivateHeader from "components/header/PrivateHeader";
import Feedback from "components/feedback/Feedback";

const FeedbackPage = () => {

    return (
        <div className="fixed w-full h-full left-0 top-0 overflow-scroll" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PrivateHeader/>
            <Feedback/>
        </div>
    )
};

export default FeedbackPage;