import React from "react";

import PrivateHeader from "components/header/PrivateHeader";
import Profile from "components/profile/Profile";

const ProfilePage = () => {
    return (
        <div>
            <PrivateHeader/>
            <div className="ml-48 mt-8">
                <Profile/>
            </div>
        </div>
    );
};

export default ProfilePage;