import { configureStore } from '@reduxjs/toolkit';
import auth from './slices/auth';
import errors from './slices/errors';
import propertyFeatures from './slices/propertyFeatures';
import propertyInfo from './slices/propertyInfo';
import listingState from './slices/listingState';
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';

const persistConfig = {
    key: "root",
    version: 1,
    whitelist: ["auth"],
    storage: storage
};


const persistedReducer = persistCombineReducers(persistConfig, {
    auth: auth,
    errors: errors,
    propertyFeatures: propertyFeatures,
    propertyInfo: propertyInfo,
    listingState: listingState,
});


const store = configureStore({ 
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
    }),
});

export default store;