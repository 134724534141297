import React, { useEffect, useState } from "react";

import { convertStringDate } from "utils/listings";
import DeleteCasePopup from "components/popups/DeleteCasePopup";


const IndividualListing = ({
    listingId,
    address,
    addedDate,
    lastEditedDate,
}) => {

    const [convertedAddedDate, setConvertedAddedDate] = useState(null);
    const [convertedLastEditedDate, setConvertedLastEditedDate] = useState(null);
    const [deleteCase, setDeleteCase] = useState(false);

    useEffect(() => {
        // convert added date to proper format
        const addedDateString = convertStringDate(addedDate);
        setConvertedAddedDate(addedDateString);

        // the same for the last edited date
        const lastEditedDateString = convertStringDate(lastEditedDate);
        setConvertedLastEditedDate(lastEditedDateString);
    }, []);


    return (
        <div className="w-full h-[9.5rem] bg-white border border-solid border-black/20 rounded-xl flex flex-col justify-center gap-4">
            {deleteCase ? <DeleteCasePopup listingId={listingId} setOpen={setDeleteCase}/>: null}
            <div className="ml-6 mt-4 mb-0">
                <a 
                    className="block mr-6 font-medium text-normal whitespace-nowrap overflow-hidden text-ellipsis duration-200 color-inherit no-underline hover:opacity-50"
                    href={"/listings/" + listingId} 
                    target="_blank">
                        {address}
                </a>
            </div>
            <div className="ml-6 flex flex-col gap-2">
                <p className="text-sm mt-0 mb-0">Added: {convertedAddedDate}</p>
                <p className="text-sm mt-0 mb-0">Last edited: {convertedLastEditedDate}</p>
            </div>
            <div className="ml-6 flex flex-row gap-3 mb-4">
                <a 
                    className="text-normal bg-none border-none cursor-pointer p-0 no-underline transition 300 hover:opacity-50" 
                    style={{color: "rgb(24, 104, 251)"}}
                    href={"/listings/" + listingId} 
                    target="_blank">
                        Edit
                </a>
                <button 
                    className="text-normal bg-none border-none cursor-pointer p-0 no-underline transition 300 hover:opacity-50" 
                    style={{color: "rgb(255, 0, 0)"}}
                    onClick={() => setDeleteCase(true)}>
                        Delete
                </button>
            </div>
        </div>
    );

};

export default IndividualListing;