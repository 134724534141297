import React, { useEffect, useState } from "react";

import store from "state/store";
import { 
    deleteSelectedLocationFeatureById, 
    deleteSelectedImageFeatureById,
    updateSelectedLocationFeatureById,
    updateSelectedImageFeatureById,
} from "state/slices/propertyFeatures";

import deleteIcon from "assets/icons/delete_icon.svg";

const SelectedFeature = ({
    featureId,
    featureType,
    featureName,
    featureKey,
}) => {

    const [featureText, setFeatureText] = useState("");

    useEffect(() => {
        setFeatureText(featureName);
    }, [featureName]);

    function handleDelete() {
        if (featureType === "location") {
            store.dispatch(deleteSelectedLocationFeatureById(featureId));
        } else if (featureType === "image") {
            store.dispatch(deleteSelectedImageFeatureById(featureId));
        };
    };

    function handleFeatureUpdate(newFeatureText) {
        const feature = {
            "name": newFeatureText,
            "key": featureKey,
        }
        const updatePayload = {
            "featureId": featureId,
            "feature": feature,
        }
        if (featureType === "location") {
            store.dispatch(updateSelectedLocationFeatureById(updatePayload));
        } else if (featureType === "image") {
            store.dispatch(updateSelectedImageFeatureById(updatePayload));
        };
        setFeatureText(newFeatureText);
    };

    return (
        <div className="w-[70%] flex justify-between mt-2">
            <input 
                className="w-full text-normal text-black text-center pt-[0.4rem] pb-[0.4rem] pl-[1rem] pr-[1rem] box-border border border-solid border-black/50 rounded-xl focus:outline-none" 
                name='selectedFeature' 
                type='text'
                maxLength="60"
                value={featureText}
                onChange={(e) => handleFeatureUpdate(e.target.value)}>
            </input>
            <button 
                className="ml-[-2rem] cursor-pointer border-none transition duration-300 bg-inherit z-10 hover:opacity-70" 
                onClick={handleDelete}>
                    <img 
                        src={deleteIcon} 
                        className="relative w-[1.35rem] h-[1.35rem] top-[-1rem] right-[-0.25rem]"/>
            </button>
        </div>
    );
};

export default SelectedFeature;