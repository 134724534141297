import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SelectPhotos from "./SelectPhotos";
import ListingInputFields from "./ListingInputFields";
import GenerateButtons from "./GenerateButtons";

const NewListingDefault = ({scrollToBottom}) => {

    const featuresGeneratedStore = useSelector(state => state.listingState.featuresGenerated);

    const [featuresGenerated, setFeaturesGenerated] = useState(false);

    useEffect(() => {
        setFeaturesGenerated(featuresGeneratedStore);
    }, [featuresGeneratedStore]);

    return (
        <div 
            className="w-full h-full m-0 overflow-hidden flex shrink-0 min-w-[35rem] duration-300 items-center justify-start flex-col gap-2 flex-nowrap border border-solid border-black/10"
            style={{width: featuresGenerated ? "40%": "100%"}}>
                <div className="w-120 mt-4 text-center">
                    <p className="mt-0 mb-0 ml-4 font-medium text-lg text-black">Listing Information</p>
                </div>
                <SelectPhotos/>
                <ListingInputFields/>
                <GenerateButtons scrollToBottom={scrollToBottom}/>
        </div>
    )
};

export default NewListingDefault;