import React, { useRef, useState } from 'react';

import arrowDown from 'assets/icons/arrow-down-black.svg';

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
    const contentHeight = useRef()
     return(
      <div className="accordion-wrapper-container">
        <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
          <p className='question-content'>{question}</p>
          <img src={arrowDown} className={`arrow ${isOpen ? 'active' : ''}`}/>
        </button>
    
        <div ref={contentHeight} className="answer-container" style={
            isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
            }>
          <p className="answer-content">{answer}</p>
        </div>
      </div>
    )
}

const Accordion = ({data}) => {
    const [activeIndex, setActiveIndex] = useState(null);
   
    const handleItemClick = (index) => {
     setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
   
    return (
     <div className='container'>
       {data.map((item, index) => (
       <AccordionItem
        key={index}
        question={item.question}
        answer={item.answer}
        isOpen={activeIndex === index}
        onClick={() => handleItemClick(index)}
       />
      ))}
     </div>
    )
};
   
export default Accordion;