import React from "react";
import ReactGA from "react-ga4";

import PublicHeader from "components/header/PublicHeader";
import HeroSection from "components/landing/HeroSection";
import KeyFeaturesSection from "components/landing/KeyFeaturesSection";
import AdvantagesSection from "components/landing/AdvantagesSection";
import HowItWorksSection from "components/landing/HowItWorksSection";
import FAQSection from "components/landing/FAQSection";
import FooterSection from "components/landing/FooterSection";
import BeforeFooterSection from "components/landing/BeforeFooterSection";
import DemoVideo from "components/landing/DemoVideo";

const LandingPage = () => {
    ReactGA.send({ 
        hitType: "pageview", 
        page: "/", 
        title: "Main page view", 
    });

    return (
        <div className="overflow-auto">
            <PublicHeader/>
            <HeroSection/>
            {/* <DemoVideo/> */}
            <KeyFeaturesSection/>
            <AdvantagesSection/>
            <HowItWorksSection/>
            <FAQSection/>
            <BeforeFooterSection/>
            <FooterSection/>
        </div>
    );
};

export default LandingPage;