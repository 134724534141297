import React, { useState } from "react";
import { useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';
import { getListings } from "api/listings";
import IndividualListing from "./IndividualListing";


const ListListings = () => {

    const [listings, setListings] = useState([]);

    useEffect(() => {
        async function asyncWrapper() {
            const response = await getListings();
            if (response.successful === false) {
                console.log(response.data);
            };

            // attach unique keys
            const listingsWithKeys = response.data.listings.map(
                (listing) => {
                    listing["key"] = uuidv4();
                    return listing;
                }
            )
            setListings(listingsWithKeys);
        };

        asyncWrapper();
    }, []);

    return (
        <div className="w-[39rem] mt-2 flex flex-col items-center justify-center gap-6">
            {
                listings.map((listing) => (
                    <IndividualListing 
                        key={listing.key}
                        listingId={listing.id}
                        address={listing.address} 
                        addedDate={listing.createdAt}
                        lastEditedDate={listing.updatedAt}
                    />
                ))
            }
        </div>
    );
};

export default ListListings;