import React from "react";

import store from "state/store";
import { addSelectedLocationFeature } from "state/slices/propertyFeatures";

import addIcon from "assets/icons/add_icon.svg";

const PredictedLocationFeature = ({locationFeature}) => {

    function handleSelectFeature() {
        // avoid adding identical features,
        // otherwise, ID collision occurs inside DOM
        const selectedLocationFeatures = store.getState().propertyFeatures.selectedLocationFeatures;
        for (let i = 0; i < selectedLocationFeatures.length; i++) {
            if (selectedLocationFeatures[i].key == locationFeature.key) {
                return;
            }
        }
        const feature = {
            "name": locationFeature.name,
            "key": locationFeature.key,
        }
        store.dispatch(addSelectedLocationFeature(feature));
    };

    return (
        <div className="w-[85%] max-h-[5.5rem] min-h-[5.5rem] border border-solid border-black/70 rounded-xl flex flex-row gap-0">
            <div className="w-[85%] overflow-scroll scrollbar-hide">
                <p className="ml-3 mt-2 mr-2 text-normal whitespace-nowrap">{locationFeature.name}</p>
                <p className="ml-3 mt-2 mr-6 text-xs mb-4">
                    Based on: {
                                locationFeature.locations.map((location, i) => (
                                    <a href={location.url} className={location.url ? "text-blue-600 cursor-pointer": "hover:opacity-100"} target="_blank" rel="noopener noreferrer" key={location.key}>
                                        {location.name}{i+1 === locationFeature.locations.length ? "": ",  "}
                                    </a>
                                ))
                            }
                </p>
            </div>

            <div className="w-[15%]">
                <button className="w-full h-full flex justify-center items-center cursor-pointer hover:opacity-60 transition duration-300 content-center" onClick={handleSelectFeature}>
                    <img className="w-[2.4rem] h-[2.4rem]" src={addIcon}/>
                </button>
            </div>
        </div>
    );
};

export default PredictedLocationFeature;