import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import store from "state/store";
import { removeAddressError, removeDesiredLengthError } from "state/slices/errors";
import { 
    updateAddress,
    updateDesiredLength,
    updatePropertyType,
    updateListingType,
} from "state/slices/propertyInfo";


const ListingInputFields = () => {

    const [address, setAddress] = useState(null);
    const [desiredLength, setDesiredLength] = useState(null);
    const [propertyType, setPropertyType] = useState("apartment");
    const [listingTypeChecked, setListingTypeChecked] = useState(false);

    const addressErrorStore = useSelector(state => state.errors.newListing.addressError);
    const desiredLengthErrorStore = useSelector(state => state.errors.newListing.desiredLengthError);

    const [addressError, setAddressError] = useState(null);
    const [desiredLengthError, setDesiredLengthError] = useState(null);

    // pre-load info from storage, in case an existing listing was opened
    useEffect(() => {
        setAddress(store.getState().propertyInfo.address);
        setDesiredLength(store.getState().propertyInfo.desiredLength);
        setPropertyType(store.getState().propertyInfo.propertyType);
        const listingType = store.getState().propertyInfo.listingType;
        if (listingType === "rent") {
            setListingTypeChecked(false);
        } else {
            setListingTypeChecked(true);
        };
    }, []);

    // handle enum values (property and listing types)
    function handlePropertyTypeChange(newPropertyType) {
        setPropertyType(newPropertyType);
        store.dispatch(updatePropertyType(newPropertyType))
    };

    function handleListingTypeChange() {
        const newState = !listingTypeChecked
        setListingTypeChecked(newState);
        if (newState === false) {
            store.dispatch(updateListingType("rent"))
        } else {
            store.dispatch(updateListingType("purchase"))
        }
    };

    useEffect(() => {
        setAddressError(addressErrorStore);
    }, [addressErrorStore]);

    useEffect(() => {
        setDesiredLengthError(desiredLengthErrorStore);
    }, [desiredLengthErrorStore]);

    const filterDigits = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
    };

    return (
        <div className="w-[28rem] mt-0 flex flex-col justify-center align-center gap-0">

            <div className="w-full h-22 mt-2 flex flex-row justify-between items-start">
                <div className="w-[19rem]">
                    <label className="block mb-2.5 ml-1.5 text-normal text-black">Address</label>
                    <input 
                        className="w-full p-[0.4rem] text-black rounded-lg border border-solid border-black/40 text-sm transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused" 
                        placeholder="221B Baker St, London NW1 6XE, UK"
                        defaultValue={address ? address : ""}
                        onChange={(e) => {
                            setAddress(e.target.value); 
                            store.dispatch(updateAddress(e.target.value));
                            setAddressError(null);
                            store.dispatch(removeAddressError());
                        }}>
                    </input>
                    <div className="h-4 mt-2 ml-1 mb-0">
                        {
                            (addressError && addressError.trim() !== '') && 
                            <p className="text-xs text-color-text-error">{addressError}</p>
                        }
                    </div>
                </div>

                <div className="w-[7.5rem]">
                    <label className="block mb-2.5 ml-1 text-normal text-black">Listing length</label>
                    <input 
                        className="w-full p-[0.4rem] text-black rounded-lg border border-solid border-black/40 text-sm transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused" 
                        placeholder="250 (words)"
                        value={desiredLength ? desiredLength : ""}
                        onKeyPress={(e) => filterDigits(e)} 
                        onChange={(e) => {
                            setDesiredLength(e.target.value); 
                            store.dispatch(updateDesiredLength(e.target.value));
                            setDesiredLengthError(null);
                            store.dispatch(removeDesiredLengthError());
                        }}>
                    </input>
                    <div className="h-4 mt-2 ml-1 mb-0">
                        {
                            (desiredLengthError && desiredLengthError.trim() !== '') && 
                            <p className="text-xs text-color-text-error">{desiredLengthError}</p>
                        }
                    </div>
                </div>

            </div>

            <div className="w-full h-18 mt-2 flex flex-row justify-between items-start">
                <div className="w-[13rem]">
                    <label className="block mb-2.5 ml-1 text-normal text-black">Property type</label>
                    <div className="select-property-type-wrapper">
                        <select 
                            name="propertyType" 
                            className="select-property-type__field" 
                            value={propertyType}
                            onChange={e => handlePropertyTypeChange(e.target.value)}>
                            <option value="apartment">Apartment</option>
                            <option value="house">House</option>
                            <option value="flat">Flat</option>
                            <option value="house">House</option>
                            <option value="studio">Studio</option>
                            <option value="condo">Condo</option>
                        </select>
                    </div>
                </div>

                <div className="w-[13rem]">
                    <label className="block mb-2.5 ml-1 text-normal text-black">Listing type</label>
                    <div>
                        <input 
                            type="checkbox" id="toggle" className="toggle-listing-type" 
                            // defaultChecked={listingTypeChecked}
                            checked={listingTypeChecked}
                            onChange={() => handleListingTypeChange()}
                        />
                        <label htmlFor="toggle" className='toggle-listing-type-labels'>
                            <div className="toggle-listing-type-labels__rent">Rent</div>   
                            <div className="toggle-listing-type-labels__purchase">Purchase</div>
                        </label>
                    </div>
                </div>

            </div>

        </div>
    )
};

export default ListingInputFields;