import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";

import PredictedImageFeatures from "./PredictedImageFeatures";
import PredictedLocationFeatures from "./PredictedLocationFeatures";

const PredictedFeatures = () => {

    const featuresGeneratedStore = useSelector(state => state.listingState.featuresGenerated);

    const [featuresGenerated, setFeaturesGenerated] = useState(false);

    // show features when they are generated
    useEffect(() => {
        setFeaturesGenerated(featuresGeneratedStore);
    }, [featuresGeneratedStore])

    return (
        <div className={`h-full w-[30%] border-b border-solid border-black/10 ${featuresGenerated ? '' : 'hidden'}`}>
            <div className="h-[97%]">
                <PredictedLocationFeatures/>
                <PredictedImageFeatures/>
            </div>
        </div>
    )
};

export default PredictedFeatures;