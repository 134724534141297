import React, { useEffect, useState,  } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import queryString from "query-string";
import { jwtDecode } from "jwt-decode";

import store from "state/store";
import { setTokens, updateUserInfo } from "state/slices/auth";
import { createUserInternal } from "api/auth";
import LoadingOAuthPopup from "components/popups/LoadingOAuthPopup";
import { getCurrentUser } from 'api/user';
import { determineNewUser } from "utils/users";
import { convertStringDate } from "utils/listings";


const OAuthRedirect = () => {

    const { hash } = useLocation();
    const [loginError, setLoginError] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        
        async function asyncWrapper() {

            setLoadingUser(true);

            // retrieve all pararms after a hash from the URL
            const params = queryString.parse(hash);
            const accessToken = params["access_token"];
            const refreshToken = params["refresh_token"];

            // try to create a user internally with decoded info
            const decodedToken = jwtDecode(accessToken);
            const response = await createUserInternal(
                decodedToken["email"],
                "agent",
                "google",
                accessToken,
            )

            if (response["successful"] === false) {
                setLoginError(response["data"]);
                return;
            };

            // if it was successful, set all the auth info and redirect
            const tokens = {
                "accessToken": accessToken,
                "refreshToken": refreshToken,
            };
            store.dispatch(setTokens(tokens));

            // retreive the current user info
            const requestUserInfo = await getCurrentUser();
            if (requestUserInfo["successful"] === false) {
                console.log(requestUserInfo.data);
            }
            const userData = requestUserInfo.data;
            userData.registeredAt = convertStringDate(userData.registeredAt);
            store.dispatch(updateUserInfo(userData));

            // determine the redirect page (whether the user is new or not)
            let redirectURL = "/listings"
            const newUser = determineNewUser(userData.registeredAt);
            if (newUser === true) {
                redirectURL = "/listings/new?tutorial=true";
            }

            setLoadingUser(false);

            navigate(redirectURL);
        };

        asyncWrapper();
    }, []);

    return (
        <div className="w-full">
            {loadingUser ? <LoadingOAuthPopup/>: null}
            {
                (loginError && loginError.trim() !== "") && 
                <p className="mt-20 text-center text-2xl">{loginError}</p>
            }
        </div>
    )
};

export default OAuthRedirect;