import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import store from 'state/store';
import { logUserOut } from 'state/slices/auth';


const Profile = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState(null);
    const [registeredAt, setRegisteredAt] = useState(null);

    // load the user info from storage
    useEffect(() => {
        const userInfo = store.getState().auth.userInfo;
        setEmail(userInfo.email);
        setRegisteredAt(userInfo.registeredAt);
    }, []);

    const handleLogUserOut = () => {
        store.dispatch(logUserOut());
        navigate("/login");
    };

    return (
        <div>
            <div className="flex flex-col gap-12">
                <p className="text-2xl font-bold">Hello there 👋</p>

                <div className="flex flex-col gap-4">
                    <p className="text-xl font-bold">Account details</p>
                    <div className="flex flex-row gap-2">
                        <p className="w-[9rem]">Email:</p> 
                        <p>{email}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                        <p className="w-[9rem]">Joined at:</p> 
                        <p>{registeredAt}</p>
                    </div>

                    <div className="flex flex-col gap-4 mt-4">
                        <button 
                            className="text-normal text-white p-[0.25rem] w-[15rem] border border-solid border-black/40 rounded-lg color-white cursor-pointer transition duration-300 ease-in-out hover:opacity-70" 
                            style={{backgroundColor: "rgb(37, 175, 96)"}}
                            onClick={() => navigate("/feedback")}>
                                Contact Us
                        </button>
                        <button 
                            className="text-normal text-white p-[0.25rem] w-[15rem] border border-solid border-black/40 rounded-lg color-white cursor-pointer transition duration-300 ease-in-out hover:opacity-70" 
                            style={{backgroundColor: "rgb(220, 36, 36)"}}
                            onClick={handleLogUserOut}>
                                Log Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;