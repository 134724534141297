import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "newListing": {
        "imagesError": null,
        "addressError": null,
        "desiredLengthError": null,
    }
}

const errorsSlice = createSlice({
    name: "errors",
    initialState,
    reducers: {
        updateAddressError: (state, action) => {
            state.newListing.addressError = action.payload
        },
        removeAddressError: (state) => {
            state.newListing.addressError = null
        },
        updateDesiredLengthError: (state, action) => {
            state.newListing.desiredLengthError = action.payload
        },
        removeDesiredLengthError: (state) => {
            state.newListing.desiredLengthError = null
        },
    }
});

export const {
    updateAddressError,
    removeAddressError,
    updateDesiredLengthError,
    removeDesiredLengthError,
} = errorsSlice.actions;

export default errorsSlice.reducer;