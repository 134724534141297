import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import SelectedFeature from "./SelectedFeature";
import AddFeature from "./AddFeature";


const SelectedLocationFeatures = () => {

    const selectedLocationFeaturesStore = useSelector(state => state.propertyFeatures.selectedLocationFeatures);

    const [selectedLocationFeatures, setSelectedLocationFeatures] = useState([]);

    useEffect(() => {
        setSelectedLocationFeatures(selectedLocationFeaturesStore);
    }, [selectedLocationFeaturesStore]);

    return (
        <div className="h-1/2 flex flex-col items-center justify-start gap-6">
            <p className="text-center mt-4 mb-0 text-lg font-medium">Selected Location features</p>
            <div className="w-[70%]">
                <AddFeature featureType={"location"}/>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 overflow-scroll">
            {
                selectedLocationFeatures.map((feature, featureId) => (
                    <SelectedFeature
                        key={feature.key}
                        featureId={featureId}
                        featureType={"location"} 
                        featureName={feature.name}
                        featureKey={feature.key}
                    />
                ))
            }
            </div>
        </div>
    );
};

export default SelectedLocationFeatures;
