import React, {useEffect, useRef, useState} from "react";

import { MAX_IMAGES } from "utils/constants";

import { v4 as uuidv4 } from 'uuid';
import store from "state/store";
import { updateImages } from "state/slices/propertyInfo";
import TooManyImagesPopup from "components/popups/TooManyImagesPopup";

import selectImageIcon from "assets/icons/select_image.svg";
import leftScroll from "assets/icons/scroll_left.svg";
import rightScroll from "assets/icons/scroll_right.svg";


const SelectPhotos = () => {

    const ref = useRef(null);

    const [images, setImages] = useState([]);
    const [tooManyImagesSelected, setTooManyImagesSelected] = useState(false);

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    // pre-load info from storage, in case an existing listing was opened
    useEffect(() => {
        setImages(store.getState().propertyInfo.images);
    }, []);

    useEffect(() => {
        store.dispatch(updateImages(images));
    }, [images])

    const handleImageSelect = (e) => {
        if (e.target.files && e.target.files[0]) {

            let newImages = []
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = {
                    "imageURL": URL.createObjectURL(e.target.files[i]),
                    "imageBlob": e.target.files[i],
                    "key": uuidv4(),
                }
                newImages.push(newImage);
            };

            // check if there are already too many images
            if (images.length === MAX_IMAGES) {
                setTooManyImagesSelected(true);
                return;
            };

            if (newImages.length > 0) {
                setImages([
                    ...images,
                    ...newImages,
                ]);
            };
        };
    };

    const handleImageDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let newImages = []
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                const newImage = {
                    "imageURL": URL.createObjectURL(e.dataTransfer.files[i]),
                    "imageBlob": e.dataTransfer.files[i],
                    "key": uuidv4(),
                }
                newImages.push(newImage);
            };

            // check if there are already too many images
            if (images.length + newImages.length > MAX_IMAGES) {
                setTooManyImagesSelected(true);
                return;
            };

            if (newImages.length > 0) {
                setImages([
                    ...images,
                    ...newImages,
                ]);
            };
        };
    };

    const handleImageDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };
    
    const handleImageDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleImageDelete = (imageToDelete) => {
        const imagesModified = images.filter(function(image) {
            return image !== imageToDelete
        });
        setImages(imagesModified);
    };

    return (
        <div className="w-[35rem] mt-2 flex flex-col items-center content-center flex-wrap gap-4">
            {tooManyImagesSelected ? <TooManyImagesPopup setOpen={setTooManyImagesSelected}/> : null}
            <div className="w-full">
                <p className="mt-0 mb-0 ml-16 text-base text-black inline-block">Select photos</p>
            </div>

            <div className="w-full h-44 flex items-center justify-center">
                <label 
                        className="w-4/5 h-full text-center pt-8 text-xs border border-solid border-black border-dashed transition-all duration-200 cursor-pointer hover:opacity-55" 
                        style={{borderImageSource: "url('https://i.stack.imgur.com/LKclP.png')", borderImageSlice: "0.5", borderImageRepeat: "round"}}
                        htmlFor="file-upload"
                        onDrop={(e) => handleImageDrop(e)}
                        onDragEnter={(e) => handleImageDragEnter(e)}
                        onDragOver={(e) => handleImageDragOver(e)} multiple>
                        <img src={selectImageIcon} className="display-block ml-auto mr-auto w-16 mb-2 transition-all duration 20"/>
                        <p className="text-black"><b>Drag & Drop file here</b><br/>or click to browse</p>
                </label>
                <input 
                    id="file-upload" 
                    type="file"
                    accept=".jpeg,.png,.jpg"
                    className="hidden"
                    onChange={handleImageSelect}
                    onDrop={(e) => handleImageDrop(e)}
                    onDragEnter={(e) => handleImageDragEnter(e)}
                    onDragOver={(e) => handleImageDragOver(e)} multiple/>
            </div>

                <div className="w-full h-28 flex flex-row items-center content-center justify-center flex-wrap gap-3">
                    <button 
                        className="ursor-pointer border-none transition-all duration-300 bg-inherit hover:opacity-50"  
                        onClick={() => scroll(-60)}>
                            <img className="w-[1.5rem] h-[2.5rem" src={leftScroll}/>
                    </button>
                    <div className="w-4/5 h-full flex flex-row content-start items-center overflow-scroll scroll-smooth scrollbar-hide border border-solid text-black/20 rounded-lg" ref={ref}>
                        {
                            images.map((image) => (
                                <div className="w-[7rem] h-[5rem] ml-3 shrink-0 relative" key={image.key}>
                                    <img alt="preview image" src={image.imageURL} className="w-full h-full rounded-md"/>
                                    <button 
                                        className="w-[1.5rem] h-[1.5rem] absolute right-0.5 top-px cursor-pointer bg-[url('/src/assets/icons/delete_image.svg')] bg-no-repeat border-none transition-all duration-300 hover:opacity-50" 
                                        style={{backgroundSize: "1.8rem"}}
                                        onClick={() => handleImageDelete(image)}>
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                    <button 
                        className="cursor-pointer border-none transition-all duration-300 bg-inherit hover:opacity-50" 
                        onClick={() => scroll(60)}>
                            <img className="w-[1.5rem] h-[2.5rem]" src={rightScroll}/>
                    </button>
                </div>

        </div>
    )
};

export default SelectPhotos;