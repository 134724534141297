import { apiPrivate, apiPublic } from "./base";


export async function uploadImages(listingId, imagesBlob) {
    try {
        // convert files into formData
        let formData = new FormData();
        for(let i = 0; i < imagesBlob.length; i++){
            const file = new Blob([imagesBlob[i]], {type: 'image/jpeg'})
            formData.append("images", file);
        }

        const response = await apiPrivate.put(
            `/listings/${listingId}/images`, 
            formData, 
            {headers: {'Content-Type': 'multipart/form-data'}}
        );
        return {
            "successful": true,
            "data": response.data
        };
    } catch (error) {
        return {
            "successful": false,
            "data": error
        };
    };
}


export async function getImageNames(listingId, demo) {
    try {
        let response = null;
        if (demo === true) {
            response = await apiPublic.get(`/demo/listings/${listingId}/images`);
        } else {
            response = await apiPrivate.get(`/listings/${listingId}/images`);
        }
        return {
            "successful": true,
            "data": response.data,
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
};

export async function downloadImage(listingId, listingName, demo) {
    try {
        let response = null;
        if (demo === true) {
            response = await apiPublic.get(
                `demo/listings/${listingId}/images/${listingName}`, 
                {responseType: 'blob'},
            );
        } else {
            response = await apiPrivate.get(
                `/listings/${listingId}/images/${listingName}`, 
                {responseType: 'blob'},
            );   
        }
        return {
            "successful": true,
            "data": response.data,
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
};

export async function downloadImages(listingId, demo) {
    try {
        const images = [];

        // get image names
        const responseImageNames = await getImageNames(listingId, demo);
        if (responseImageNames.successful === false) {
            return {
                "successful": false,
                "data": responseImageNames.data,
            }
        }
        const imageNames = responseImageNames.data.imageNames;

        // download them in parallel
        const imageDownloadRequests = imageNames.map(
            async (imageName) => downloadImage(listingId, imageName, demo)
        );
        const downloadImagesResponse = await Promise.all(imageDownloadRequests);

        // convert images to the internal format
        downloadImagesResponse.forEach(downloadImageResponse => {
            if (downloadImageResponse.successful === false) {
                return {
                    "successful": false,
                    "data": downloadImageResponse.data,
                }
            }
            
            // convet to the internal format
            // NOTE: content should not be touched here, only coverted to blob at the end
            const newImage = {
                "imageURL": URL.createObjectURL(downloadImageResponse.data),
                "imageBlob": downloadImageResponse.data,
            };
            images.push(newImage);
        });
        return {
            "successful": true,
            "data": images,
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
};