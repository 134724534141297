import React from "react";

import store from "state/store";
import { addSelectedImageFeature } from "state/slices/propertyFeatures";

import addIcon from "assets/icons/add_icon.svg";

const PredictedImageFeature = ({imageFeature}) => {

    function handleSelectFeature() {
        // avoid adding identical features,
        // otherwise, ID collision occurs inside DOM
        const selectedImageFeatures = store.getState().propertyFeatures.selectedImageFeatures;
        for (let i = 0; i < selectedImageFeatures.length; i++) {
            if (selectedImageFeatures[i]["key"] == imageFeature.key) {
                return;
            }
        }
        store.dispatch(addSelectedImageFeature(imageFeature));
    };

    return (
        <div className="w-[85%] min-h-[2.5rem] border border-solid border-black/70 rounded-xl flex flex-row gap-0">
            <div className="w-[87.5%] flex items-center">
                <p className="ml-3 mr-3 align-middle whitespace-nowrap scrollbar-hide overflow-x-scroll">{imageFeature.name}</p>
            </div>

            <div className="w-[12.5%]">
                <button className="w-full h-full flex justify-center items-center cursor-pointer hover:opacity-60 transition duration-300 content-center" onClick={handleSelectFeature}>
                    <img className="w-[2rem] h-[2rem]" src={addIcon}/>
                </button>
            </div>
        </div>
    )
};

export default PredictedImageFeature