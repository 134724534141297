import React from "react";
import { useNavigate } from "react-router-dom";

import propertyStockImage from 'assets/images/property_stock_image_2.png';
import apartmentsLogo from 'assets/logos/apartments_logo.png';
import realtorLogo from 'assets/logos/realtor_logo.png';
import rightmoveLogo from 'assets/logos/rightmove_logo.png';
import zilloweLogo from 'assets/logos/zillow_logo.png';


const HeroSection = () => {

    const navigate = useNavigate();

    function HandleTryClick() {
        navigate("/register");
    };

    return (
        <div className="w-full h-[80vh] max-h-[40rem] flex justify-center" style={{backgroundColor: "rgb(22, 24, 46)"}}>
            <div className="flex flex-row w-[80%] max-w-[80rem] h-full items-center">
                <div className="w-1/2 h-full mt-[12.5rem] mb-8">
                        <p className="text-white font-medium text-3xl w-[32rem]">Use AI to write listing descriptions that just sell</p>
                        <p className="text-white text-xl mt-40 w-[30rem]">Automatically generate perfect text from your property photos and address</p>
                        <button 
                            className="w-[15rem] mt-10 pb-[0.5rem] pt-[0.5rem] text-xl text-white cursor-pointer duration-300 rounded-lg border border-solid border-black/35 hover:opacity-75" 
                            style={{backgroundColor: "rgb(37, 175, 96)"}}
                            onClick={HandleTryClick}>
                                Get Started
                        </button>
                        <p className="text-white text-sm mt-2">Completely free!</p>
                </div>
                <div className="w-1/2 h-full mt-[12.5rem] flex flex-col items-center mb-8">
                    <img src={propertyStockImage} className="w-[30rem] h-[18rem] rounded-2xl"/>
                    <p className="text-white text-5xl mt-4">{'\u2193'}</p>
                    <div className="w-[32rem] h-[4rem] bg-white rounded-3xl mt-4">
                        <div className="w-full h-full flex flex-row justify-between items-center">
                            <img src={zilloweLogo} className="w-[6rem] h-[1.25rem] ml-4"></img>
                            <img src={rightmoveLogo} className="w-[6rem] h-[1.4rem]"></img>
                            <img src={apartmentsLogo} className="w-[7rem] h-[1.25rem]"></img>
                            <img src={realtorLogo} className="w-[8rem] h-[1.5rem] mr-4"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;