import { getListing } from "api/listings";
import { downloadImages } from "api/images";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import store from "state/store";
import { useSelector } from "react-redux";
import { 
    updateAddress,
    updateDesiredLength,
    updatePropertyType,
    updateListingType,
    updateImages
} from "state/slices/propertyInfo";
import { 
    updatePredictedImageFeatures,
    updatePredictedLocationFeatures,
    updateSelectedImageFeatures,
    updateSelectedLocationFeatures,
    updatePropertyDescription,
} from "state/slices/propertyFeatures";
import { 
    updateFeaturesGenerated,
    updateDescritpionGenerated,
} from "state/slices/listingState";

import PrivateHeader from "components/header/PrivateHeader";
import NewListing from "components/listings/new_listing/NewListing";
import GeneratedDescription from "components/listings/new_listing/generated_description/GeneratedDescription";
import LoadingListingPopup from "components/popups/LoadingListingPopup";


const ListingPage = () => {

    // add address to the page title
    const [address, setAddress] = useState(null);
    useEffect(() => {
        if (address === null) {
            document.title = "Listing - PropStory.ai"
        } else {
            document.title = `${address} - PropStory.ai`
        }
    }, [address]);

    const {listingId} = useParams();
    const pageEndRef = useRef(null);

    const propertyInfoSavedStore = useSelector(state => state.listingState.listingSaved);

    const [propertyInfoSaved, setPropertyInfoSaved] = useState(false);
    const [loadingListing, setLoadingListing] = useState(true);

    // if the info was saved
    useEffect(() => {
        setPropertyInfoSaved(propertyInfoSavedStore);
    }, [propertyInfoSavedStore]);


    // prevent leaving without saving
    useEffect(() => {
        const alertUser = e => {
            e.preventDefault();
            e.returnValue = '';
        };

        if (propertyInfoSaved === false) {
            window.addEventListener('beforeunload', alertUser);
        };
        return () => {
          window.removeEventListener('beforeunload', alertUser);
        }
    }, []);

    // scroll when generated
    // when component is still null, wait a bit for it ti rerender
    function scrollToBottom() {
        if (pageEndRef.current === null) {
            setTimeout(() => pageEndRef.current.scrollIntoView({behavior: 'smooth'}), 500)
            return;
        };
        pageEndRef.current.scrollIntoView({behavior: 'smooth'});
    };

    // assign values to store from the API
    useEffect(() => {
        async function asyncWrapper() {
            
            // retrieve the listing data (with images in parallel)
            const [listingResponse, imageResponse] = await Promise.all([
                getListing(listingId, false),
                downloadImages(listingId, false),
            ]);
            if (listingResponse.successful === false) {
                console.log(listingResponse.data);
                return;
            }
            if (imageResponse.successful === false) {
                console.log(imageResponse.data);
                return;
            }
            const listingData = listingResponse.data;
            const images = imageResponse.data;

            // attach UUIDs for keys to all array elements
            images.forEach(imageElement => {
                imageElement["key"] = uuidv4();
            });

            const locationFeaturesSelected = [];
            listingData.locationFeaturesSelected.forEach(feature => {
                locationFeaturesSelected.push({
                    "name": feature,
                    "key": uuidv4(),
                })
            });
            const imageFeaturesSelected = [];
            listingData.imageFeaturesSelected.forEach(feature => {
                imageFeaturesSelected.push({
                    "name": feature,
                    "key": uuidv4(),
                })
            });
            const locationFeaturesPredicted = listingData.locationFeaturesPredicted.map(
                (feature) => {
                    feature["key"] = uuidv4();
                    feature.locations.forEach(
                        (location) => {
                            location["key"] = uuidv4();
                            return location;
                        }
                    )
                    return feature;
                }
            )
            const predictedImageFeatures = listingData.imageFeaturesPredicted.map(
                (feature) => {
                    return {
                        "name": feature,
                        "key": uuidv4(),
                    }
                }
            )

            // field values
            store.dispatch(updateAddress(listingData.address));
            store.dispatch(updateDesiredLength(listingData.desiredDescriptionLength));
            store.dispatch(updatePropertyType(listingData.propertyType));
            store.dispatch(updateListingType(listingData.listingType));

            // features and predictions
            store.dispatch(updatePredictedImageFeatures(predictedImageFeatures));
            store.dispatch(updatePredictedLocationFeatures(locationFeaturesPredicted));
            store.dispatch(updateSelectedImageFeatures(imageFeaturesSelected));
            store.dispatch(updateSelectedLocationFeatures(locationFeaturesSelected));
            store.dispatch(updatePropertyDescription(listingData.description));

            // images
            store.dispatch(updateImages(images))

            // update the global state
            setPropertyInfoSaved(true);
            store.dispatch(updateFeaturesGenerated(true));
            store.dispatch(updateDescritpionGenerated(true));

            // update title with the address
            setAddress(listingData.address);

            setLoadingListing(false);
        }

        asyncWrapper();
    }, [])

    return (
        <div className="w-full h-full m-0 flex-nowrap overflow-scroll">
            {loadingListing ? <LoadingListingPopup/>: null}
            <PrivateHeader/>
            {
                !loadingListing ?
                <div>
                    <div className="w-full h-[93vh] m-0">
                        <NewListing scrollToBottom={scrollToBottom}/>
                    </div>
                    <div className="w-full h-[100vh] m-0" ref={pageEndRef}>
                        <GeneratedDescription/>
                    </div>
                </div>: null
            }
        </div>
    )
};

export default ListingPage;