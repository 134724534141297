import React from "react";

const PremiumRequiredPopup = ({setOpen}) => {

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[60%] w-[28rem] h-[30rem] text-center margin-auto flex flex-col items-center justify-center gap-8 rounded-2xl border-black bg-white z-50">
                <p className="text-xl text-black font-semibold mt-0 mb-0">Premium required</p>
                <p className="text-lg text-left text-black whitespace-pre-wrap ml-12 mr-12 mt-0 mb-0">
                    Sorry, but you account does not have a previum activated, or the free trial has expired{"\n\n"}

                    It means you cannot use the generation capabilities, but you still keep all previous records{"\n\n"}

                    To manage you sbuscription plan, consider visiting the settings page <a href="/profile" target="_blank" className="text-blue-600 cursor-pointer duration-300 hover:opacity-70">here</a>
                    </p>
                <button 
                    className="pb-[0.2rem] pt-[0.2rem] pl-[2.5rem] pr-[2.5rem] text-normal text-white cursor-pointer duration-300 rounded-xl border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundColor: "rgb(73, 70, 235)"}}
                    onClick={() => setOpen(false)}>
                        Got it
                </button>
            </div>
        </div>
    )
};

export default PremiumRequiredPopup;