import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PredictedLocationFeature from "./PredictedLocationFeature";

const PredictedLocationFeatures = () => {

    const predictedLocationFeaturesStore = useSelector(state => state.propertyFeatures.predictedLocationFeatures);

    const [predictedLocationFeatures, setPredictedLocationFeatures] = useState([]);

    useEffect(() => {
        setPredictedLocationFeatures(predictedLocationFeaturesStore);
    }, [predictedLocationFeaturesStore]);


    return (
        <div className="h-1/2 flex flex-col items-center justify-start gap-6 border-b border-solid border-black/20">
                <p className="text-center mt-4 mb-0 text-lg font-medium">Suggested Location features</p>
            <div className="w-full flex flex-col items-center justify-start gap-4 overflow-scroll">
                {
                    predictedLocationFeatures.map((locationFeature) => (
                        <PredictedLocationFeature
                            key={locationFeature.key}
                            locationFeature={locationFeature}
                        />
                    ))
                }
            </div>
        </div>
    )
};

export default PredictedLocationFeatures;