import React, {useEffect} from "react";

import PrivateHeader from "components/header/PrivateHeader";
import ListListings from "components/listings/list_listings/ListListings";
import ListListingsTop from "components/listings/list_listings/ListListingsTop";

const ListingsPage = () => {

    useEffect(() => {
        document.title = "Listings - PropStory.ai"
    }, []);

    return (
        <div className="w-full h-full fixed left-0 top-0 overflow-scroll" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PrivateHeader/>
            <div className="mt-4 mb-8 flex flex-col items-center justify-center">
                <ListListingsTop/>
                <ListListings/>
            </div>
        </div>
    )
};

export default ListingsPage;