import { createClient } from '@supabase/supabase-js';
import { APP_URL, SUPABASE_PUBLIC_KEY, SUPABASE_URL } from 'utils/constants';

const supabase = createClient(SUPABASE_URL, SUPABASE_PUBLIC_KEY, {
    auth: {
        persistSession: false,
        autoRefreshToken: false,
    },
});

export async function createUser(email, password) {
    const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
    })

    // to get error details -> error.message (no docs for this :/)
    if (error !== null) {
        console.log(error)
        let errorMessage = "error-creating-user"
        if (error.status === 422) {
            errorMessage = "user-already-exists"
        }
        return {
            "successful": false,
            "data": errorMessage
        };
    }

    const userData = {
        "accessToken": data["session"]["access_token"],
        "refreshToken": data["session"]["refresh_token"],
        "userID": data["user"]["id"]
    }

    return {
        "successful": true,
        "data": userData
    };
}

export async function loginUser(email, password) {
    const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
    })

    if (error !== null) {
        let errorMessage = "error-logingin-user"
        if (error.status === 400) {
            errorMessage = "invalid-cridentials"
        }
        return {
            "successful": false,
            "data": errorMessage
        };
    }

    const userData = {
        "accessToken": data["session"]["access_token"],
        "refreshToken": data["session"]["refresh_token"],
        "userID": data["user"]["id"]
    }

    return {
        "successful": true,
        "data": userData
    };
}

export async function refreshAccessToken(refreshToken) {
    const { data, error } = await supabase.auth.refreshSession({refresh_token: refreshToken})

    if (error !== null) {
        return {
            "successful": false,
            "data": error.message
        };
    }

    const userData = {
        "accessToken": data["session"]["access_token"],
        "refreshToken": data["session"]["refresh_token"],
        "userID": data["user"]["id"]
    }

    return {
        "successful": true,
        "data": userData
    };
}

export async function continueWithGoogle() {
    await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
            redirectTo: `${APP_URL}/oauth-redirect`
        }
    }) 
}
