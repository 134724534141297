import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "listingSaved": true,
    "featuresGenerated": false,
    "descriptionGenerated": false,
    "demoListingOpen": false,
    "featuresGenerationAborted": false,
    "descriptionGenerationAborted": false,
}

const listingStateSlice = createSlice({
    name: "listingState",
    initialState,
    reducers : {
        updateListingSaved: (state, action) => {
            state.listingSaved = action.payload
        },
        updateFeaturesGenerated: (state, action) => {
            state.featuresGenerated = action.payload
        },
        updateDescritpionGenerated: (state, action) => {
            state.descriptionGenerated = action.payload
        },
        updateDemoListingOpen: (state, action) => {
            state.demoListingOpen = action.payload
        },
        setFeaturesGenerationAborted: (state, action) => {
            state.featuresGenerationAborted = action.payload
        },
        setDescriptionGenerationAborted: (state, action) => {
            state.descriptionGenerationAborted = action.payload
        },
    }
});

export const {
    updateListingSaved,
    updateFeaturesGenerated,
    updateDescritpionGenerated,
    updateDemoListingOpen,
    setFeaturesGenerationAborted,
    setDescriptionGenerationAborted,
} = listingStateSlice.actions;

export default listingStateSlice.reducer;