import { Navigate } from 'react-router-dom';
import store from 'state/store';

const PrivateRoute = ({children}) => {
    const userLoggedIn = store.getState().auth.userLoggedIn;
    return(
        userLoggedIn ? children: <Navigate to="/login" /> 
    )
}

export default PrivateRoute;