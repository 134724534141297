import React from "react";


const ListListingsTop = () => {

    return (
        <div className="w-[40rem] h-[3.5rem] flex items-center">
            <p className="mr-auto ml-4 text-lg">Property Listings</p>
            <button 
                className="bg-[url(/src/assets/icons/add_listing_icon.svg)] bg-right bg-no-repeat text-normal mr-4 h-[2.25rem] w-[5.25rem] text-left pl-[0.6rem] cursor-pointer duration-200 border border-solid border-black/50 rounded-xl bg-white hover:opacity-60"  
                style={{backgroundSize: "35% auto", backgroundPosition: "90%"}}
                onClick={(e) => {
                    e.preventDefault();
                    window.open("listings/new", "_blank");
                }}> 
                    New
            </button>
        </div>
    );
};

export default ListListingsTop;