import React, { useEffect, useState } from "react";

import { v4 as uuidv4 } from 'uuid';
import store from "state/store";
import { addSelectedLocationFeature, addSelectedImageFeature } from "state/slices/propertyFeatures";
import TooManyFeaturesPopup from "components/popups/TooManyFeaturesPopup";
import { MAX_SELECTED_FEAUTRES } from "utils/constants";

import addIcon from "assets/icons/add_icon.svg";

const AddFeature = ({featureType}) => {

    const [placeholderText, setPlaceholderText] = useState(null);
    const [featureText, setFeatureText] = useState("");
    const [tooManyFeatures, setTooManyFeatures] = useState(false);

    useEffect(() => {
        if (featureType === "location") {
            setPlaceholderText("Type in a location feature");
        } else if (featureType === "image") {
            setPlaceholderText("Type in a property feature");
        }
    }, [featureType]);

    function handleAdd () {
        if (featureText === "") {
            return;
        }
        const feature = {
            "name": featureText,
            "key": uuidv4(),
        }
        if (featureType === "location") {
            const selectedLocationFeatures = store.getState().propertyFeatures.selectedLocationFeatures
            if (selectedLocationFeatures.length + 1 > MAX_SELECTED_FEAUTRES) {
                setTooManyFeatures(true);
                return;
            };
            store.dispatch(addSelectedLocationFeature(feature));
        } else if (featureType === "image") {
            const selectedImageFeatures = store.getState().propertyFeatures.selectedImageFeatures
            if (selectedImageFeatures.length + 1 > MAX_SELECTED_FEAUTRES) {
                setTooManyFeatures(true);
                return;
            };
            store.dispatch(addSelectedImageFeature(feature));
        };
        setFeatureText("");
    };

    return (
        <div className="w-full flex justify-between">
            {tooManyFeatures ? <TooManyFeaturesPopup featureType={featureType} setOpen={setTooManyFeatures}/>: null}
            <button 
                className="mr-[-6rem] cursor-pointer border-none bg-inherit z-10 hover:opacity-70" 
                onClick={handleAdd}>
                    <img 
                        className="ml-[0.5rem] w-[1.75rem] h-[1.75rem] relative align-middle"
                        src={addIcon}/>
            </button>
            <input 
                className="w-full pt-[0.4rem] pb-[0.4rem] pl-[2.5rem] text-normal text-black box-border border border-solid border-black/50 rounded-lg focus:outline-none" 
                autoComplete="off"
                name='typedLocationFeature' 
                type='text'
                maxLength="60"
                onChange={(e) => {setFeatureText(e.target.value)}}
                value={featureText}
                placeholder={placeholderText}/>
        </div>
    );
};

export default AddFeature;