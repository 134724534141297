import React from 'react';

import logo from 'assets/icons/propstory_logo.png'

const PublicHeader = () => {
    return (
        <div className="w-full h-[7vh] max-h-14 flex items-center" style={{backgroundColor: "rgb(22, 24, 46)"}}>
                <a href="/" className="mr-auto ml-4 no-underline hover:opacity-50 transition-all duration-200">
                    <img src={logo} className="w-[11rem] h-[2.5rem]"></img>
                </a>
                <a href="/register" className="mr-8 text-white font-medium text-base bg-transparent border-none p-0 cursor-pointer hover:opacity-50 transition-all duration-200">Register</a>
                <a href="/login" className="mr-8 text-white font-medium text-base bg-transparent border-none p-0 cursor-pointer hover:opacity-50 transition-all duration-200">Log In</a>
        </div>
    )
};

export default PublicHeader;