import React from 'react';
import { useNavigate } from "react-router-dom";

import store from 'state/store';
import { logUserOut } from 'state/slices/auth';

import logo from 'assets/icons/propstory_logo.png';
import profileIcon from 'assets/icons/profile_icon.svg';


const PrivateHeader = () => {

    const navigate = useNavigate();


    return (
        <div className="w-full h-[7vh] max-h-14 flex items-center" style={{backgroundColor: "rgb(22, 24, 46)"}}>
                <a href='/listings' className="mr-auto ml-4 no-underline hover:opacity-50 transition-all duration-200">
                    <img src={logo} className="w-[11rem] h-[2.5rem]"></img>
                </a>
                <button 
                    className="mr-8 text-white text-base bg-transparent border-none p-0 cursor-pointer hover:opacity-50 transition-all duration-200" 
                    onClick={() => navigate("/profile")}>
                        <img src={profileIcon} className="h-[1.5rem] w-[1.5rem]"/>
                </button>
        </div>
    )
};

export default PrivateHeader;