
export function convertStringDate(stringDate) {
    const dateTimeStamp = Date.parse(stringDate);
    const date = new Date(dateTimeStamp);
    const dateFormatted = date.toISOString().substring(0, 10);
    return dateFormatted;
};

export function addDaysToStringDateAndConvert(stringDate, days) {
    const dateTimeStamp = Date.parse(stringDate);
    let date = new Date(dateTimeStamp);
    date.setDate(date.getDate() + days);
    const dateFormatted = date.toISOString().substring(0, 10);
    return dateFormatted;
};


export function validateListingTextFieldsError(address, desiredLength) {

    let errors = {
        "address": null,
        "desiredLength": null,
    }
    
    if (!address && !desiredLength) {
        errors.address = "Required field";
        errors.desiredLength = "Required field";
        return errors;
    } else if (!address) {
        errors.address = "Required field";
        return errors
    } else if (!desiredLength) {
        errors.desiredLength = "Required field";
        return errors
    };

    if (desiredLength > 1500) {
        errors.desiredLength = "Max legnth: 1500";
        return errors
    } else if (desiredLength < 100) {
        errors.desiredLength = "Min legnth: 100";
        return errors
    };

    return null;
};

export function countWords(text) {
    if (text !== "" & text !== null) {
        return text.trim().split(/\s+/).length;
    } else {
        return 0;
    }
};