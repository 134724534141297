import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import store from "state/store";
import { countWords } from "utils/listings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLisitng, patchListing } from "api/listings";
import { uploadImages } from "api/images";
import { useNavigate } from "react-router-dom";
import ImageFormattingFailurePopup from "components/popups/ImageFormattingFailurePopup";
import { IMAGE_FORMAT_ERRORS } from "utils/constants";


const GeneratedDescription = () => {

    const navigate = useNavigate();
    const {listingId} = useParams();

    const descriptionTextStore = useSelector(state => state.propertyFeatures.propertyDescription);

    const [wordsCount, setWordsCount] = useState(0);
    const [descriptionText, setDescriptionText] = useState("Hello there");
    const [imageFormattingFailed, setImageFormattingFailed] = useState(false);

    
    useEffect(() => {
        setDescriptionText(descriptionTextStore);
        setWordsCount(countWords(descriptionTextStore));
    }, [descriptionTextStore])

    function showToastMessageCopied() {
        toast.success("Description copied");
    };


    function handleDescriptionUpdate(text) {
        setDescriptionText(text);
        setWordsCount(countWords(text));
    };

    function handleCopy() {
        navigator.clipboard.writeText(descriptionText);
        showToastMessageCopied();
    };

    async function handleSave() {
        // prohibit actions if this is a demo listing
        const demoListing = store.getState().listingState.demoListingOpen;
        if (demoListing === true) {
            return
        }

        // remove the "key" part from elements
        const selectedLocationFeatures =  store.getState().propertyFeatures.selectedLocationFeatures.map(
            (feature) => feature.name
        )
        const selectedImageFeatures = store.getState().propertyFeatures.selectedImageFeatures.map(
            (feature) => feature.name
        )
        const predictedLocationFeatures = []
        store.getState().propertyFeatures.predictedLocationFeatures.forEach(
            (feature) => {
                // remove IDs from locations
                const locations = []
                feature.locations.forEach(
                    (location) => {
                        const locationCorrected = {
                            "name": location.name,
                            "url": location.url,
                        }
                        locations.push(locationCorrected)
                    }
                )
                const featureCorrected = {
                    "name": feature.name,
                    "locations": locations,
                };
                predictedLocationFeatures.push(featureCorrected);
            }
        )
        const predictedImageFeatures = store.getState().propertyFeatures.predictedImageFeatures.map(
            (feature) => feature.name
        )
        const payload = {
            "imageFeaturesPredicted": predictedImageFeatures,
            "locationFeaturesPredicted": predictedLocationFeatures,
            "imageFeaturesSelected": selectedImageFeatures,
            "locationFeaturesSelected": selectedLocationFeatures,
            "description": descriptionText,
            "listingType": store.getState().propertyInfo.listingType,
            "propertyType": store.getState().propertyInfo.propertyType,
            "desiredDescriptionLength": parseInt(store.getState().propertyInfo.desiredLength, 10),
            "address": store.getState().propertyInfo.address,
        };
        // save the new listing if it's a "new" page, otherwise PATCH
        let response;
        if (typeof listingId === 'undefined') {
            response = await createLisitng(payload);
        } else {
            response = await patchListing(listingId, payload);
        }
        if (response.successful == false) {
            console.log(response.data);
            return;
        };

        // re-attach listingId, if new is created
        let listingIdExisting;
        if (typeof(listingId) === 'undefined') {
            listingIdExisting = response.data.id;
        } else {
            listingIdExisting = listingId;
        }

        // upload images (the same for PATCH and POST, as we replace all anyways)
        const images = store.getState().propertyInfo.images;
        const imagesBlob = []
        for(let i = 0; i < images.length; i++){
            imagesBlob.push(images[i].imageBlob);
        }
        const imageResponse = await uploadImages(
            listingIdExisting,
            imagesBlob,
        )

        // image formatting errors
        if (imageResponse.successful === false) {
            setImageFormattingFailed(true);
            return;
        };

        navigate("/listings");
    };

    return (
        <div className="w-full h-[100vh] flex flex-col items-center justify-center gap-4">
            <ToastContainer autoClose={1500}/>
            {imageFormattingFailed ? <ImageFormattingFailurePopup setOpen={setImageFormattingFailed}/>: null}
            <p className="text-black text-xl text-center font-semibold mb-0 mt-0">Property Description</p>

            <div className="w-[70%]">
                <p className="ml-4 text-black text-lg">Words count: {wordsCount}</p>
            </div>

            <textarea 
                className="w-[70%] h-[75%] pl-6 pr-6 pt-4 pb-4 resize-none border border-solid border-black/50 rounded-lg"
                value={descriptionText}
                onChange={(e) => handleDescriptionUpdate(e.target.value)}>
                    Hello there
            </textarea>

            <div className="w-[70%] mb-8 mt-4 flex flex-row justify-center items-center gap-6">
                <button
                    className="bg-[url(/src/assets/icons/copy_icon.svg)] bg-no-repeat w-[14rem] pl-[2.5%] pt-[0.2rem] pb-[0.2rem] text-normal text-white cursor-pointer duration-300 rounded-lg border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundColor: "rgb(41, 162, 254)", backgroundPosition: "14%"}}
                    onClick={handleCopy}>
                        Copy Description
                </button>
                <button
                    className="w-[13rem] pb-[0.2rem] pt-[0.2rem] text-normal text-white cursor-pointer duration-300 rounded-lg border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundColor: "rgb(37, 175, 96)"}}
                    onClick={async () => {await handleSave();}}>
                    Save
                </button>
            </div>

        </div>
    );
};

export default GeneratedDescription;
