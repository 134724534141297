import { apiPublic } from "./base";


export async function createUserInternal(email, role, authMethod, token) {
  try {
    const data = {
        "email": email,
        "role": role,
        "authMethod": authMethod,
    };
    const headers = {
      "Authorization": `Bearer ${token}`
    }
    
    const response = await apiPublic.post("/users", data, {headers: headers});
    return {
      "successful": true,
      "data": response.data
    };
  } catch (error) {
    console.log(error)
    let errorDetails = "";
    if (error.response.status == 409) {
      errorDetails = error.response["data"]["detail"]
    } else {
      errorDetails = "Some unkown error occured. Please, try again"
    };
    return {
      "successful": false,
      "data": errorDetails
    };
  };
};
