import './App.css';
import React from 'react';
import { 
  Route, 
  Routes, 
  BrowserRouter as Router, 
} from "react-router-dom";
import ReactGA from 'react-ga4';

import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

import LandingPage from 'pages/LandingPage';
import FAQPage from 'pages/FAQPage';
import ContactPage from 'pages/ContactPage';
import PricingPage from 'pages/PricingPage';
import ListingsPage from 'pages/ListingsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import OAuthRedirectPage from './pages/OAuthRedirectPage';
import ListingPage from './pages/ListingPage';
import NewListingPage from './pages/NewListingPage';
import FeedbackPage from 'pages/FeedbackPage';
import DemoListingPage from 'pages/DemoListingPage';
import ProfilePage from 'pages/ProfilePage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';

function App() {
  ReactGA.initialize('G-1QSBVSX6B6');

  return (
      <Router>
        <Routes>
          <Route path='' element={<PublicRoute><LandingPage /></PublicRoute>}/>
          <Route path='faq' element={<PublicRoute><FAQPage /></PublicRoute>}/>
          <Route path='contact' element={<PublicRoute><ContactPage /></PublicRoute>}/>
          {/* <Route path='pricing' element={<PublicRoute><PricingPage /></PublicRoute>}/> */}
          <Route path='privacy-policy' element={<PublicRoute><PrivacyPolicyPage /></PublicRoute>}/>
          {/* <Route path='terms-and-conditions' element={<PublicRoute><TermsAndConditionsPage /></PublicRoute>}/> */}
          <Route path='demo/listings/:listingId' element={<PublicRoute><DemoListingPage /></PublicRoute>}/>

          <Route path='login' element={<PublicRoute><LoginPage /></PublicRoute>}/>
          <Route path='register' element={<PublicRoute><RegisterPage /></PublicRoute>}/>
          <Route path='oauth-redirect' element={<PublicRoute><OAuthRedirectPage /></PublicRoute>}/>

          <Route path='listings' element={<PrivateRoute><ListingsPage /></PrivateRoute>}/>
          <Route path='listings/:listingId' element={<PrivateRoute><ListingPage /></PrivateRoute>}/>
          <Route path='listings/new' element={<PrivateRoute><NewListingPage /></PrivateRoute>}/>
          <Route path='feedback' element={<PrivateRoute><FeedbackPage /></PrivateRoute>}/>
          <Route path='profile' element={<PrivateRoute><ProfilePage /></PrivateRoute>}/>
        </Routes>
      </Router>
  );
}

export default App;
