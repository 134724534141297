import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "predictedImageFeatures": [],
    "predictedLocationFeatures": [],
    "selectedImageFeatures": [],
    "selectedLocationFeatures": [],
    "propertyDescription": null,
}

const propertyFeaturesSlice = createSlice({
    name: "propertyFeatures",
    initialState,
    reducers: {
        updatePredictedImageFeatures: (state, action) => {
            state.predictedImageFeatures = action.payload
        },
        updatePredictedLocationFeatures: (state, action) => {
            state.predictedLocationFeatures = action.payload
        },
        updateSelectedImageFeatures: (state, action) => {
            state.selectedImageFeatures = action.payload
        },
        addSelectedImageFeature: (state, action) => {
            state.selectedImageFeatures = [
                action.payload,
                ...state.selectedImageFeatures,
            ]
        },
        updateSelectedImageFeatureById: (state, action) => {
            const { featureId, feature } = action.payload
            state.selectedImageFeatures = [
                ...state.selectedImageFeatures.slice(0, featureId),
                feature,
                ...state.selectedImageFeatures.slice(featureId+1)
            ]
        },
        deleteSelectedImageFeatureById: (state, action) => {
            state.selectedImageFeatures = state.selectedImageFeatures.filter(
                (_, featureId) => featureId != action.payload
            )
        },
        updateSelectedLocationFeatures: (state, action) => {
            state.selectedLocationFeatures = action.payload
        },
        addSelectedLocationFeature: (state, action) => {
            state.selectedLocationFeatures = [
                action.payload,
                ...state.selectedLocationFeatures,
        ]
        },
        updateSelectedLocationFeatureById: (state, action) => {
            const { featureId, feature } = action.payload
            state.selectedLocationFeatures = [
                ...state.selectedLocationFeatures.slice(0, featureId),
                feature,
                ...state.selectedLocationFeatures.slice(featureId+1)
            ]
        },
        deleteSelectedLocationFeatureById: (state, action) => {
            state.selectedLocationFeatures = state.selectedLocationFeatures.filter(
                (_, featureId) => featureId != action.payload
            )
        },
        updatePropertyDescription: (state, action) => {
            state.propertyDescription = action.payload
        },
    }
});

export const {
    updatePredictedImageFeatures,
    updatePredictedLocationFeatures,
    updateSelectedImageFeatures,
    addSelectedImageFeature,
    updateSelectedImageFeatureById,
    deleteSelectedImageFeatureById,
    updateSelectedLocationFeatures,
    addSelectedLocationFeature,
    updateSelectedLocationFeatureById,
    deleteSelectedLocationFeatureById,
    updatePropertyDescription,
} = propertyFeaturesSlice.actions;

export default propertyFeaturesSlice.reducer;