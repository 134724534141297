import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import store from 'state/store';
import { getCurrentUser } from 'api/user';
import { convertStringDate } from 'utils/listings';
import { logUserOut, setTokens, updateUserInfo } from 'state/slices/auth';
import { validateRegisterFormError } from 'utils/auth';
import { createUserInternal } from 'api/auth';
import { continueWithGoogle, createUser } from 'auth/auth';


const RegisterForm = () => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [registerError, setRegisterError] = useState(null);

    const navigate = useNavigate();

    const handleRegister = async () => {
        const formError = validateRegisterFormError(
            email, 
            password,
        );
        if (formError !== null) {
            setRegisterError(formError);
            return;
        };

        // first, register a Supabase user
        const responseSupabase = await createUser(email, password);
        if (responseSupabase.successful === false) {
            if (responseSupabase.data === "user-already-exists") {
                setRegisterError("User with this email already exists");
            } else {
                setRegisterError("Some unkown error occured. Please, try again");
            };
            return;
        };
        const dataSupabase = responseSupabase.data;

        // second, register the internal user
        const responseInternal = await createUserInternal(
            email,
            "agent",
            "password",
            dataSupabase.accessToken,
        );
        if (responseInternal.successful === false) {
            setRegisterError(responseInternal.data);
            return;
        };

        const tokens = {
            "accessToken": dataSupabase.accessToken,
            "refreshToken": dataSupabase.refreshToken,
        }
        store.dispatch(setTokens(tokens));

        // retreive the current user info
        const requestUserInfo = await getCurrentUser();
        if (requestUserInfo["successful"] === false) {
            setRegisterError("Some unkown error occured. Please, try again");
            store.dispatch(logUserOut());
            return;
        }
        const userData = requestUserInfo.data;
        userData.registeredAt = convertStringDate(userData.registeredAt);
        store.dispatch(updateUserInfo(userData));

        navigate("/listings/new?tutorial=true");
    };

    return (
        <div className="w-[28rem] h-[28.5rem] bg-white flex flex-col items-center border border-solid border-black/50 rounded-lg">

            <div className="w-[65%] mt-6 mb-0 flex flex-col items-center">
                <p className="mt-0 mb-0 font-medium text-2xl">Good to see you!</p>
                <p className="mt-1">Please enter your details to register</p>
                <div className="w-full h-[1rem] mb-1 mt-2 flex justify-center items-center">
                    {
                        (registerError && registerError.trim() !== '') && 
                        <p className="text-center text-xs" style={{color: "rgb(183, 3, 3)"}}>
                            {registerError}
                        </p>
                    }
                </div>
            </div>

            <div className="w-[65%] mt-2 flex flex-col justify-between items-center gap-4">
                <div className="w-full">
                    <label className="block mb-2">Email</label>
                    <input 
                        className="w-full pt-[0.5rem] pb-[0.5rem] pl-[0.75rem] text-black rounded-md border border-solid border-black/30 text-sm transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused"
                        name="email"
                        type="text"
                        placeholder="Enter your email" 
                        onChange={(e) => {setEmail(e.target.value); setRegisterError(null)}}>    
                    </input>
                </div>

                <div className="w-full">
                    <label className="block mb-2">Password</label>
                    <input 
                        className="w-full pt-[0.5rem] pb-[0.5rem] pl-[0.75rem] text-black rounded-md border border-solid border-black/30 text-sm transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused"
                        name="password"
                        type="password" 
                        placeholder="Enter your password" 
                        onChange={(e) => {setPassword(e.target.value); setRegisterError(null)}}>    
                    </input>
                </div>

            </div>

            <div className="w-[65%] mt-8 flex flex-col justify-between items-center gap-3">
                <button 
                    className="w-full h-[2.2rem] text-white cursor-pointer duration-300 rounded-lg border border-solid border-black/30 hover:opacity-60"
                    style={{backgroundColor: "rgb(78, 90, 206)"}}
                    onClick={async () => {await handleRegister();}}>
                        Register
                </button>
                <button 
                    className="w-full h-[2.25rem] bg-[url(/src/assets/icons/google_logo.png)] bg-no-repeat pl-[30%] text-left text-sm text-black cursor-pointer duration-300 rounded-lg border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundPosition: "22%", backgroundSize: "6.5% auto"}}
                    onClick={async () => {await continueWithGoogle();}}>
                        Continue with Google
                </button>
            </div>

            <div className="w-[65%] text-center mt-4">
                <p className="text-xs">
                    Already have an account?{" "}
                    <a 
                        className="font-bold no-underline duration-300" 
                        style={{color: "rgb(78, 90, 206)"}} 
                        href="/login">
                            Sign In
                    </a>
                </p>
            </div>

        </div>
    )

};

export default RegisterForm;