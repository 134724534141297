import React from "react";

import lawIcon from 'assets/icons/law_icon.svg';
import lightBulbIcon from 'assets/icons/light_bulb_icon.svg';
import searchIcon from 'assets/icons/search_icon.svg';


const AdvantagesSection = () => {
    return (
        <div className="h-[18rem] flex items-center justify-center" style={{backgroundColor: "rgb(35, 40, 101)"}}>
            <div className="w-full max-w-[90rem] flex flex-row gap-0 items-start" style={{backgroundColor: "rgb(35, 40, 101)"}}>
                <div className="w-1/3 flex flex-col justify-center items-center align-center">
                    <div className="w-[65%] flex flex-row gap-2 items-center">
                        <img src={lightBulbIcon} className="h-[2.5rem] w-[2.5rem]"/>
                        <p className="text-white text-xl font-bold">Cut costs and save time</p>
                    </div>
                    <p className="text-white text-lg w-[70%] mt-6">Before, you either had to spend an hour typing a description, or pay 20$+ for a single listing on freelance.</p>
                </div>

                <div className="w-1/3 flex flex-col justify-center items-center align-center">
                    <div className="w-[65%] flex flex-row gap-2 items-center">
                        <img src={lawIcon} className="h-[2.5rem] w-[2.5rem]"/>
                        <p className="text-white text-xl font-bold">Avoid potential scrutiny</p>
                    </div>
                    <p className="text-white text-lg w-[70%] mt-6">The generated description adheres to the Fair Housing Act, so it would always be free of any prejudice or discrimination.</p>
                </div>

                <div className="w-1/3 flex flex-col justify-center items-center align-center">
                    <div className="w-[65%] flex flex-row gap-2 items-center">
                        <img src={searchIcon} className="h-[2.5rem] w-[2.5rem]"/>
                        <p className="text-white text-xl font-bold">SEO-optimised text</p>
                    </div>
                    <p className="text-white text-lg w-[70%] mt-6">A lot of people use search engines to find a property, so discoverability plays a big factor in the success of the listing.</p>
                </div>
            </div>
        </div>
    );
};

export default AdvantagesSection;