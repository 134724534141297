import React from "react";

import closeIcon from "assets/icons/close_icon.svg";

import TutorialSlider from "./TutorialSlider";

const Tutorial = ({setOpen}) => {

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[60%] w-[37rem] h-[36rem] text-center margin-auto flex flex-col items-center justify-start rounded-2xl border-black bg-white z-50">
                <button 
                    className="w-[1.5rem] h-[1.5rem] mr-4 mt-4 mb-2 self-end cursor-pointer border-none duration-300 bg-inherit hover:opacity-50" 
                    onClick={() => setOpen(false)}>
                        <img src={closeIcon} className="w-[1.5rem] h-[1.5rem]" />
                </button>
                <TutorialSlider/>
            </div>
        </div>
    )
};

export default Tutorial;