import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "accessToken": null,
    "refreshToken": null,
    "userLoggedIn": false,
    "userInfo": null,
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setTokens: (state, action) => {
            const { accessToken, refreshToken } = action.payload
            state.accessToken = accessToken
            state.refreshToken = refreshToken
            state.userLoggedIn = true
        },
        updateTokens: (state, action) => {
            const { accessToken, refreshToken } = action.payload
            state.accessToken = accessToken
            state.refreshToken = refreshToken
        },
        logUserOut: (state) => {
            state.accessToken = null
            state.refreshToken = null
            state.userLoggedIn = false
            state.userInfo = null
        },
        updateUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
    }
});

export const { setTokens, updateTokens, logUserOut, updateUserInfo } = authSlice.actions;

export default authSlice.reducer;