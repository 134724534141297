import React from "react";

import { deleteListing } from "api/listings";


const DeleteCasePopup = ({listingId, setOpen}) => {

    const handleDeleteListing = async () => {
        const response = await deleteListing(listingId);
        if (response.successful === false) {
            console.log(response.date);
            return;
        };
        setOpen(false);
        window.location.reload();
    }

    return (
    <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[75%] w-[32rem] h-[15rem] text-center margin-auto flex flex-col items-center justify-center gap-8 rounded-2xl border-black bg-white z-50">
            <p className="text-xl text-black font-semibold mt-0 mb-0">Delete</p>
            <p className="text-lg text-left text-black whitespace-pre-wrap ml-8 mr-8 mt-0 mb-0">Are you sure you want to delete this listing?</p>

            <div className="mt-4 flex flex-row items-center justify-center gap-6"> 
                <button 
                    className="text-normal text-white cursor-pointer pt-[0.3rem] pb-[0.3rem] pl-5 pr-5 mt-0 mb-0 rounded-xl border border-solid border-black/30 duration-300 hover:opacity-70" 
                    style={{backgroundColor: "rgb(37, 175, 96)"}}
                    onClick={() => setOpen(false)}>
                        Cancel
                </button>
                <button 
                    className="text-normal text-white cursor-pointer pt-[0.3rem] pb-[0.3rem] pl-5 pr-5 mt-0 mb-0 rounded-xl border border-solid border-black/30 duration-300 hover:opacity-70" 
                    style={{backgroundColor: "rgb(237, 69, 69)"}}
                    onClick={async () => {await handleDeleteListing();}}>
                        Delete
                </button>
            </div>

        </div>
    </div>
    );
};

export default DeleteCasePopup;