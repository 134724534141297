import React from "react";
import { useNavigate } from "react-router-dom";

const BeforeFooterSection = () => {

    const navigate = useNavigate();

    function HandleTryClick() {
        navigate("/register");
    };

    return (
        <div className="w-full h-[18rem] flex flex-col items-center gap-2" style={{backgroundColor: "rgb(35, 40, 101)"}}>
            <div className="w-full text-center mt-12">
                <p className="text-2xl font-bold text-white">Improve your property listings and get back hours of your time</p>
                <p className="text-lg text-white">Use PropStory.ai to become a better agent, today</p>
            </div>
            <button 
                className="w-[15rem] mt-10 pb-[0.5rem] pt-[0.5rem] text-xl text-white cursor-pointer duration-300 rounded-lg border border-solid border-black/35 hover:opacity-75" 
                style={{backgroundColor: "rgb(37, 175, 96)"}}
                onClick={HandleTryClick}>
                    Get Started
            </button>

        </div>
    );
};

export default BeforeFooterSection;