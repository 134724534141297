import React, { useEffect } from "react";

import RegisterForm from "components/auth/RegisterForm";
import PublicHeader from "components/header/PublicHeader";


const RegisterPage = () => {

    useEffect(() => {
        document.title = "Register - PropStory.ai"
    }, []);

    return (
        <div className="fixed w-full h-full left-0 top-0 overflow-scroll" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PublicHeader/>
            <div className="flex justify-center mt-20">
                <RegisterForm/>
            </div>
        </div>
    )
};

export default RegisterPage;