// src/mocks/handlers.js
import { http, delay, HttpResponse } from 'msw'
import { API_URL } from 'utils/constants';
 
const BASE_URL = API_URL;

export const handlers = [
    // property decription
    http.post(`${BASE_URL}/predictions/property-description`, async () => {
      // await delay(3000);
      return HttpResponse.json({
          "propertyDescription": "This inviting rental property in London offers a delightful living experience with its modern amenities and pleasant atmosphere. Perfectly suited for a variety of residents, the home combines functional design with contemporary style.\n\nAs you step inside, you are greeted by a bright and welcoming kitchen, illuminated by large windows that let in an abundance of natural light. The kitchen is equipped with essential and functional appliances, including a space-saving washer and dryer, set against a backdrop of a clean and modern design. Here, cooking and dining become a daily pleasure.\n\nThe living area is both comfortable and spacious, creating a cozy and inviting atmosphere. It's enhanced by touches of greenery, adding a serene element to your relaxation or social gatherings. The large windows continue into the bathroom, filling the space with natural light and highlighting the modern glass shower enclosure.\n\nThroughout the property, you'll appreciate the pristine and well-maintained condition. The layout is thoughtfully designed to be both pleasant and functional, ensuring a comfortable living environment. The overall ambiance is bright and welcoming, with a clean and modern design that makes the home immediately appealing.\n\nNestled in a convenient location, the property is close to essential amenities like local supermarkets, providing everyday convenience. Residents can enjoy easy access to rail transport, making commuting hassle-free. The area also offers lush urban parks for outdoor enjoyment and sporting and fitness hubs for those who like to stay active.\n\nThis property is a superb choice for anyone looking to live in a vibrant area of London while enjoying the comforts of a well-designed home. Contact us to arrange a viewing and take the first step towards making this delightful property your new home."
      })
    }),
    http.post(`${BASE_URL}/predictions/image-description`, async () => {
      return HttpResponse.json({
          "features": [
              "Historical sites nearby Historical sites nearby Historical sites nearby",
              "Good lighting",
              "Well-maintained kitchen",
              "Functional layout",
              "Lots of green space",
              "Abundant natural light",
              "Beautiful backyard garden",
              "Open-floor plan",
          ]
      })
    }),
    // location features
    http.post(`${BASE_URL}/predictions/location-description`, async () => {
      // await delay(3000);
      return HttpResponse.json({
          "features": [
            {
              "name": "Historical sites nearby Historical sites nearby Historical sites nearby",
              "locations": [
                {
                  "name": "The British Museum",
                  "url": "https://maps.google.com/?cid=3436696222068785831"
                },
                {
                  "name": "The British Library",
                  "url": "https://maps.google.com/?cid=1770007535949962021"
                }
              ]
            },
            {
              "name": "Educational institutions around",
              "locations": [
                {
                  "name": "UCL Main Campus",
                  "url": "https://maps.google.com/?cid=16338444858592137815"
                },
                {
                  "name": "University College London",
                  "url": "https://maps.google.com/?cid=14990449611398452030"
                }
              ]
            },
            {
              "name": "Healthcare centers in vicinity",
              "locations": [
                {
                  "name": "University College Hospital",
                  "url": "https://maps.google.com/?cid=14297603414019525515"
                },
                {
                  "name": "Great Ormond Street Hospital for Children",
                  "url": "https://maps.google.com/?cid=15612400047616809418"
                }
              ]
            },
            {
              "name": "Exceptional transit access",
              "locations": [
                {
                  "name": "Euston",
                  "url": "https://maps.google.com/?cid=2407203864907688631"
                },
                {
                  "name": "St Pancras International",
                  "url": "https://maps.google.com/?cid=13343444208639466770"
                },
                {
                  "name": "King’s Cross",
                  "url": "https://maps.google.com/?cid=8928889629044324603"
                },
                {
                  "name": "King's Cross St. Pancras",
                  "url": "https://maps.google.com/?cid=8962492401453605584"
                },
                {
                  "name": "Tottenham Court Road",
                  "url": "https://maps.google.com/?cid=13938659622337325530"
                },
                {
                  "name": "London Euston Station",
                  "url": null
                },
                {
                  "name": "Warren Street",
                  "url": "https://maps.google.com/?cid=15622378981757611097"
                }
              ]
            },
            {
              "name": "Shopping and Entertainment",
              "locations": [
                {
                  "name": "Primark",
                  "url": "https://maps.google.com/?cid=15874408429908719959"
                },
                {
                  "name": "The Harry Potter Shop at Platform 9 ¾",
                  "url": "https://maps.google.com/?cid=14737868406896031240"
                },
                {
                  "name": "Dominion Theatre",
                  "url": "https://maps.google.com/?cid=11609576097335612231"
                }
              ]
            },
            {
              "name": "Great food joints",
              "locations": [
                {
                  "name": "Circolo Popolare",
                  "url": "https://maps.google.com/?cid=9331157722909174934"
                }
              ]
            },
            {
              "name": "Relaxing spaces",
              "locations": [
                {
                  "name": "Russell Square",
                  "url": "https://maps.google.com/?cid=15067085060827066954"
                }
              ]
            }
          ]
        })
    }),
]