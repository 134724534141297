import React from "react";

const DemoListingPopup = ({setOpen}) => {

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[70%] w-[34rem] h-[22rem] text-center margin-auto flex flex-col items-center justify-center gap-8 rounded-2xl border-black bg-white z-50">
                <p className="text-xl text-black font-semibold mt-0 mb-0">Demo view</p>
                <p className="text-lg text-left text-black whitespace-pre-wrap ml-12 mr-12 mt-0 mb-0">
                    You are viewing a demo listing, which is read-only.{"\n\n"}
                    
                    To try the app in full, you could sign up for free <a href="/register" target="_blank" className="text-blue-600 cursor-pointer duration-300 hover:opacity-70">here</a> (no card required)
                    </p>
                <button 
                    className="pb-[0.2rem] pt-[0.2rem] pl-[2.5rem] pr-[2.5rem] text-normal text-white cursor-pointer duration-300 rounded-xl border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundColor: "rgb(73, 70, 235)"}}
                    onClick={() => setOpen(false)}>
                        Got it
                </button>
            </div>
        </div>
    )
};

export default DemoListingPopup;