import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PredictedImageFeature from "./PredictedImageFeature";

const PredictedImageFeatures = () => {

    const predictedImageFeaturesStore = useSelector(state => state.propertyFeatures.predictedImageFeatures)

    const [predictedImageFeatures, setPredictedImageFeatures] = useState([]);

    useEffect(() => {
        setPredictedImageFeatures(predictedImageFeaturesStore);
    }, [predictedImageFeaturesStore]);

    return (
        <div className="h-1/2 flex flex-col items-center justify-start gap-6">
            <p className="text-center mt-4 mb-0 text-lg font-medium">Suggested Image features</p>
            <div className="w-full flex flex-col items-center justify-start gap-3 overflow-scroll">
                    {
                        predictedImageFeatures.map((imageFeature) => (
                            <PredictedImageFeature
                                key={imageFeature.key}
                                imageFeature={imageFeature}
                            />
                        ))
                    }
            </div>
        </div>
    )
};

export default PredictedImageFeatures;