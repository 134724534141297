import React from "react";
import { Oval } from 'react-loader-spinner';

const LoadingOAuthPopup = () => {

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[75%] w-[28rem] h-[12rem] text-center margin-auto flex flex-col items-center justify-center gap-10 rounded-2xl border-black bg-white z-50">
                <p className="text-xl text-black font-semibold mt-0 mb-0">Loading the user...</p>
                <Oval
                    visible={true}
                    height="40"
                    width="40"
                    color="#699BF7"
                    secondaryColor="#699BF7"
                    strokeWidth="3.5"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass="mb-4"
                />
            </div>
        </div>
    )
};

export default LoadingOAuthPopup;