import React from "react";
import Accordion from "./Accordion";

const questionsAnswers = [
  {
      question: 'How are we different from ChatGPT?',
      answer: 'We are utilising a combination of different models that analyse images, location and selected features to achieve the final description. We also pull data from various closed sources to enhance our predictions. \n\nAll of the above cannot be done just by one person using ChatGPT (or at least it would be too expensive).' ,
  },
  {
      question: 'Why good property descriptions are important?',
      answer: 'Well-crafted property description captures attention, making potential buyers envision themselves in the space. It also allows to emphasise the benefits of the location. \n\nSometimes, this connection can be the deciding factor in motivating a buyer to take action. In addition to that, utilising keywords effectively can ensure that a listing ranks higher in search results, reaching a wider audience.',
  },
  {
      question: 'Is my data safe?',
      answer: 'Yes, we use encryption and security-first cloud storage to safeguard all data. Your data will never be shared or sold to third-parties.',
  },
  {
      question: 'How does your plan work?',
      answer: 'As of now, the app is free, and you can use it indefinitely.',
  }
];

const FAQSection = () => {
    return (
        <div className="w-full flex flex-col items-center gap-2" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            {/* Header */}
            <div className="w-full text-center">
                <p className="text-2xl font-bold mt-8">FAQ</p>
            </div>

            {/* Collapsible questions */}
            <div className="w-[55%] max-w-[55rem] mb-12 flex justify-center">
              <Accordion data={questionsAnswers}/>
            </div>
        </div>
    );
};

export default FAQSection;