import React, { useEffect } from "react";

import PublicHeader from "components/header/PublicHeader";
import OAuthRedirect from "components/auth/OAuthRedirect";

const OAuthRedirectPage = () => {

    useEffect(() => {
        document.title = "Google Login - PropStory.ai"
    }, []);

    return (
        <div className="oauth-redirect-page-container">
            <PublicHeader/>
            <div className="oauth-redirect-container">
                <OAuthRedirect/>
            </div>
        </div>
    )
};

export default OAuthRedirectPage;