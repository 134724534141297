import { apiPrivate, apiPublic } from "./base";

export async function getListings() {
    try {
        const response = await apiPrivate.get(`/listings`);
        return {
            "successful": true,
            "data": response.data
        };
    } catch (error) {
        return {
            "successful": false,
            "data": error
        };
    };
};

export async function deleteListing(listingId) {
    try {
        await apiPrivate.delete(`/listings/${listingId}`);
        return {
            "successful": true,
            "data": null
        };
    } catch (error) {
        return {
            "successful": false,
            "data": error
        };
    };
};

export async function createLisitng(createPayload) {
    try {
        const response = await apiPrivate.post(`/listings`, createPayload);
        return {
            "successful": true,
            "data": response.data,
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
}

export async function getListing(listingId, demo) {
    try {
        let response = null;
        if (demo === true) {
            response = await apiPublic.get(`/demo/listings/${listingId}`);
        } else {
            response = await apiPrivate.get(`/listings/${listingId}`);
        }
        return {
            "successful": true,
            "data": response.data,
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
};


export async function patchListing(listingId, payload) {
    try {
        await apiPrivate.patch(`/listings/${listingId}`, payload);
        return {
            "successful": true,
            "data": null,
        };
    } catch (error) {
        console.log(error)
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails,
        };
    };
}