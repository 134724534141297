import PublicHeader from "components/header/PublicHeader";
import PrivacyPolicy from "components/landing/PrivacyPolicy";
import React from "react";

const PrivacyPolicyPage = () => {
    return (
        <div className="fixed w-full h-full left-0 top-0 overflow-scroll" style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PublicHeader/>
            <div className="mt-8 mb-12 flex justify-center">
                <div className="w-[65%]">
                    <PrivacyPolicy/>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;