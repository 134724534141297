import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SelectedLocationFeatures from "./SelectedLocationFeatures";
import SelectedImageFeatures from "./SelectedImageFeatures";


const SelectedFeatures = () => {

    const featuresGeneratedStore = useSelector(state => state.listingState.featuresGenerated);

    const [featuresGenerated, setFeaturesGenerated] = useState(false);

    // show features when they are generated
    useEffect(() => {
        setFeaturesGenerated(featuresGeneratedStore);
    }, [featuresGeneratedStore])


    return (
        <div className="w-[30%] h-full overflow-x-scroll border-r border-b border-solid border-black/10">
            <div className="h-[97%]" 
            style={{display: featuresGenerated ? "": "none"}}>
                <SelectedLocationFeatures/>
                <SelectedImageFeatures/>
            </div>
        </div>
    )
};

export default SelectedFeatures;