import { apiPrivate } from "./base";

export async function generatePhotoFeatures(images) {
    try {
        // convert files into formData
        let formData = new FormData();
        for(let i = 0; i < images.length; i++){
            formData.append("images", images[i]);
        }

        const response = await apiPrivate.post(
            `/predictions/image-description`, 
            formData, 
            {headers: {'Content-Type': 'multipart/form-data'}}
        );
        return {
            "successful": true,
            "data": response.data
        };
    } catch (error) {
        let errorData = error.response.data;
        if ("detail" in errorData) {
            errorData = errorData["detail"];
        }
        console.log(errorData);
        return {
            "successful": false,
            "data": errorData
        };
    };
};


export async function generateLocationFeatures(address) {
    try {
        const data = {
            "address": address
        }
        const response = await apiPrivate.post(`/predictions/location-description`, data)

        return {
            "successful": true,
            "data": response.data
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails
        };
    };
}


export async function generateDescription(address, imageFeatures, locationFeatures, propertyType, listingType, maxLength) {
    try {
        // remove the "key" part from feature arrays
        // TODO: do the saome for image features
        const imageFeaturesStripped = imageFeatures.map((feature) => feature.name)
        const locationFeaturesStripped = locationFeatures.map((feature) => feature.name)
        const data = {
            "address": address,
            "imageFeatures": imageFeaturesStripped,
            "locationFeatures": locationFeaturesStripped,
            "listingType": listingType,
            "propertyType": propertyType,
            "desiredLength": parseInt(maxLength, 10),
        }
        const response = await apiPrivate.post(`/predictions/property-description`, data)

        return {
            "successful": true,
            "data": response.data
        };
    } catch (error) {
        const errorDetails = error.response["data"]["detail"]
        return {
            "successful": false,
            "data": errorDetails
        };
    };
}