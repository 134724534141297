import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "images": [],
    "address": null,
    "desiredLength": null,
    "propertyType": "apartment",
    "listingType": "rent",
}

const propertyInfoSlice = createSlice({
    name: "propertyInfo",
    initialState,
    reducers: {
        updateImages: (state, action) => {
            state.images = action.payload
        },
        updateAddress: (state, action) => {
            state.address = action.payload
        },
        updateDesiredLength: (state, action) => {
            state.desiredLength = action.payload
        },
        updatePropertyType: (state, action) => {
            state.propertyType = action.payload
        },
        updateListingType: (state, action) => {
            state.listingType = action.payload
        },
    }
});

export const {
    updateImages,
    updateAddress, 
    updateDesiredLength,
    updatePropertyType,
    updateListingType,
} = propertyInfoSlice.actions;

export default propertyInfoSlice.reducer;