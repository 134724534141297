import { apiPrivate, apiPublic } from "./base";

export async function sendFeedback(payload) {
    try {
        await apiPrivate.post(`/contact/feedback`, payload);
        return {
            "successful": true,
            "data": null,
        };
    } catch (error) {
        return {
            "successful": false,
            "data": error,
        };
    };
};

export async function sendMessage(payload) {
    try {
        await apiPublic.post(`/contact/message`, payload);
        return {
            "successful": true,
            "data": null,
        };
    } catch (error) {
        return {
            "successful": false,
            "data": error,
        };
    };
}