import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import { sendMessage } from "api/contact";


const Contact = () => {

    const navigate = useNavigate();

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);

    function showToastMessageSent() {
        toast.success("Message successfully sent", {
            onClose: () => {
              navigate("/listings");
            },
            autoClose: 1500
        });
    };

    async function handleSendMessage() {
        if (!name || !email || !message) {
            return;
        };

        const payloadFeedback = {
            "name": name,
            "email": email,
            "message": message,
        };

        const response = await sendMessage(payloadFeedback);
        if (response.successful === false) {
            return;
        }

        showToastMessageSent();
    }

    return (
        <div>
            <ToastContainer/>
            <div className="w-full mt-4 flex flex-col justify-center items-center gap-8">
                <div className="w-[30%] flex flex-col justify-center items-center gap-4">
                    <p className="text-xl font-bold">Contact</p>
                    <p className="text-lg text-center">Fill in the form below, and we will come back to you within 1-2 business days. Thank you!</p>
                </div>

                <div className="w-[30%] flex flex-col justify-center items-center gap-4">
                    <div className="w-full">
                        <p className="ml-2 mb-2">Name</p>
                        <input 
                            className="w-full p-[0.35rem] pl-[0.75rem] text-black rounded-lg border border-solid border-black/40 transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused"
                            onChange={(e) => {setName(e.target.value)}}>
                        </input>
                    </div>
                    <div className="w-full">
                        <p className="ml-2 mb-2">Email</p>
                        <input
                            className="w-full p-[0.35rem] pl-[0.75rem] text-black rounded-lg border border-solid border-black/40 transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused"
                            onChange={(e) => {setEmail(e.target.value)}}>
                        </input>
                    </div>
                    <div className="w-full">
                        <p className="ml-2 mb-2">Message</p>
                        <textarea 
                            className="w-full h-[15rem] resize-none p-[0.35rem] pl-[0.75rem] text-black rounded-lg border border-solid border-black/40 transition duration-300 ease-in-out focus:outline-none focus:border-color-input-focused"
                            onChange={(e) => {setMessage(e.target.value)}}>
                        </textarea>
                    </div>
                </div>

                <div className="w-[30%]">
                    <button 
                        className="w-full cursor-pointer text-normal text-white p-[0.25rem] border border-solid border-black/40 rounded-lg color-white cursor-pointer transition duration-300 ease-in-out hover:opacity-70"
                        style={{backgroundColor: "rgb(22, 24, 46)"}}
                        onClick={async () => await handleSendMessage()}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Contact;