import React from "react";

import PublicHeader from "components/header/PublicHeader";
import FAQSection from "components/landing/FAQSection";

const FAQPage = () => {
    return (
        <div className="w-full h-full m-0 " style={{backgroundColor: "rgb(248, 249, 250)"}}>
            <PublicHeader/>
            <div className="mt-4">
                <FAQSection/>
            </div>
        </div>
    );
};

export default FAQPage;