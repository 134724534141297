import React from "react";

import logo from 'assets/icons/propstory_logo.png';

const FooterSection = () => {

    const styleLink = "text-white cursor-pointer no-underline hover:opacity-50 transition-all duration-200";
    
    return (
        <div className="w-full h-[4rem] flex items-center gap-14" style={{backgroundColor: "rgb(22, 24, 46)"}}>
                <a href='/listings' className="mr-auto ml-4 no-underline hover:opacity-50 transition-all duration-200">
                    <img src={logo} className="w-[11rem] h-[2.5rem]"></img>
                </a>
                <div className="mr-8 flex flex-row gap-6">
                    <a target="_blank" href="/faq" className={styleLink}>FAQ</a>
                    <a target="_blank" href="/contact" className={styleLink}>Contact</a>
                    <a target="_blank" href="/privacy-policy" className={styleLink}>Privacy Policy</a>
                </div>
        </div>
    );
};

export default FooterSection;