import React from "react";

import { MAX_SELECTED_FEAUTRES } from "utils/constants";

const TooManyFeaturesPopup = ({setOpen, featureType}) => {

    return (
        <div className="fixed w-full h-full inset-0 bg-black/50 z-50">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[65%] w-[27rem] h-[20rem] text-center margin-auto flex flex-col items-center justify-center gap-8 rounded-2xl border-black bg-white z-50">
                <p className="text-xl font-semibold mt-0 mb-0">Too many features</p>
                <p className="text-lg text-left text-black whitespace-pre-wrap ml-8 mr-8 mt-0 mb-0">
                    {
                    `You have added too many ${featureType} fatures, the maximum amount is ${MAX_SELECTED_FEAUTRES}.\n\nThis limit is usually enough to get a good description :)`
                    }
                    </p>
                <button 
                    className="pb-[0.2rem] pt-[0.2rem] pl-[2.5rem] pr-[2.5rem] text-normal text-white cursor-pointer duration-300 rounded-xl border border-solid border-black/35 hover:opacity-60" 
                    style={{backgroundColor: "rgb(73, 70, 235)"}}
                    onClick={() => setOpen(false)}>
                        Got it
                </button>
            </div>
        </div>
    )
};

export default TooManyFeaturesPopup;